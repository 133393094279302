import React, { useState, useEffect } from "react";
import { Tabs, Tab, Spinner } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  createSalesOrder,
  getLocation,
  getProdList,
  getProductType,
  getSOStatus,
  updateProduct,
  updateSalesOrder,
} from "../../../api";
import {
  calculateAge,
  convertDateYYMMDD,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import * as name_const from "../../../common/Constants/constants";
import { Tooltip } from "react-tooltip";
import { Trash2 } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import Add_Product from "./Add_Product";
import axios from "axios";
import { s3_image_upload } from "../../../constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DesignSheetUpload from "./DesignSheetUpload";
import { screenList } from "../../../common/Labels/screenList";

const normalizeDate = (date) => {
  if (!date) return null;
  const d = new Date(date);
  if (isNaN(d)) return null; // Handle invalid dates
  d.setHours(0, 0, 0, 0); // Normalize to midnight
  return d;
};

const schema = yup.object().shape({
  customers: yup.array().of(
    yup.object().shape({
      // sales_order_name: yup.string().required("Sales order name is required"),
      location: yup
        .array()
        .min(1, "Please select location!")
        .required("Please select location!"),
      discount: yup
        .string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? 0 : value
        )
        .matches(/^\d+(\.\d+)?$/, "Enter valid discount!")
        .typeError("Enter valid discount!"),
      final_total: yup
        .string()
        .required("Enter total amount!")
        .matches(/^\d+(\.\d+)?$/, "Enter valid amount!"),
      advance_amount: yup
        .string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        )
        .matches(/^\d+(\.\d+)?$/, "Enter valid advance amount!")
        .typeError("Enter valid advance amount!"),

      order_date: yup
        .date()
        .required("Please select order date!")
        .typeError("Please select order date!"),

      approval_date: yup
        .date()
        .nullable()
        .typeError("Please select approval date!")
        .test(
          "approval-date-validation",
          "Approval date must be same as or after Order date!",
          function (value) {
            const { order_date } = this.parent;
            const approvalDate = normalizeDate(value);
            const orderDate = normalizeDate(order_date);

            if (!approvalDate) return true; // Approval date is optional
            if (!orderDate) return false; // Order date must exist if approval date is selected

            return approvalDate >= orderDate;
          }
        ),

      trail_date: yup
        .date()
        .required("Please select trial date!") // ❌ Cannot be empty
        .typeError("Please select trial date!")
        .test(
          "trial-date-validation",
          "Trial date must be same as or after Approval date (if selected), otherwise Order date!",
          function (value) {
            const { order_date, approval_date } = this.parent;
            const minDate =
              normalizeDate(approval_date) || normalizeDate(order_date);
            const trialDate = normalizeDate(value);

            return minDate && trialDate && trialDate >= minDate;
          }
        ),

      delivery_date: yup
        .date()
        .required("Please select delivery date!") // ❌ Cannot be empty
        .typeError("Please select delivery date!")
        .test(
          "delivery-date-validation",
          "Delivery date must be same as or after Trial date!",
          function (value) {
            const { trail_date } = this.parent;
            const trialDate = normalizeDate(trail_date);
            const deliveryDate = normalizeDate(value);

            return trialDate && deliveryDate && deliveryDate >= trialDate;
          }
        ),

      final_date: yup
        .date()
        .required("Please select final date!") // ❌ Cannot be empty
        .typeError("Please select final date!")
        .test(
          "final-date-validation",
          "Final date must be same as or after Delivery date!",
          function (value) {
            const { delivery_date } = this.parent;
            const deliveryDate = normalizeDate(delivery_date);
            const finalDate = normalizeDate(value);

            return deliveryDate && finalDate && finalDate >= deliveryDate;
          }
        ),

      internal_remark: yup.string().required("Special instructions required!"),

      items: yup.array().of(
        yup.object().shape({
          product: yup
            .array()
            .min(1, "Please select product !")
            .required("Please select product !"),
          price: yup
            .string()
            .required("Price is required")
            .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid price"),
        })
      ),
    })
  ),
});

const Step_Two = (props) => {
  let navigate = useNavigate();
  const { main_obj, setMain_obj, nextStep, previousStep } = props;
  const [activeTab, setActiveTab] = useState("0");
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [loc_list, setLoc_List] = useState({ data: [], loading: false });
  const [imageFiles, setImageFiles] = useState([]);
  const [approved_status, set_approved_status] = useState({ data: [] });

  const [lockBtn, setLockBtn] = useState(false);

  const [discountType, setDiscountType] = useState("disPerc"); // Default selection
  const [selectedType, setSelectedType] = useState(
    main_obj.so_data[0]?.sales_order_type || "New"
  ); // Default selection

  const [newProduct, setNewProduct] = useState("");
  const [gender, setGender] = useState("");

  const [visible, setVisible] = useState(false);

  const [visibleIndex, setVisibleIndex] = useState(null); // Track which modal is open

  const [constLabel, setConstLabel] = useState({});

  const localData = JSON.parse(localStorage.getItem("localData"));
  const def_org = localData?.organizations?.filter(
    (item) => item.default_org == true
  );

  const cst_list = main_obj.so_data?.filter((item) => item.isSelected) || [];

  const [debounceTimer, setDebounceTimer] = useState(null);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    trigger,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      customers: main_obj.so_data
        ?.filter((item) => item.isSelected) // Only include selected items
        .map(() => ({
          image_arr: imageFiles,
          discount_type: discountType,
          sales_order_name: "",
          sales_order_type: selectedType,
          order_date: new Date(),
          approval_date: null,
          trail_date: null,
          delivery_date: null,
          final_date: null,
          discount: 0.0,
          sub_total: 0.0,
          final_total: 0.0,
          advance_amount: 0.0,
          outstanding_amount: 0.0,
          items: [
            {
              product: [],
              price: 0.0,
            },
          ],
        })),
    },
  });

  const { fields } = useFieldArray({ control, name: "customers" });

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({ control, name: `customers[${activeTab}].items` });

  const validateAndNext = async () => {
    // console.log("main_obj.so_data", main_obj.so_data);
    const isValid = await trigger(`customers[${activeTab}]`);

    if (isValid) {
      const nextTab = parseInt(activeTab, 10) + 1;
      if (nextTab < fields.length) {
        setActiveTab(nextTab.toString());
      } else {
        // console.log("🚀 Final Submitted Data:", getValues());
        // console.log("main_obj", main_obj);
        const data = getValues();
        // console.log("data", data);
        if (main_obj.so_data[0]?.uuid) {
          onUpdateSubmit(data);
        } else {
          onSubmit(data);
        }
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    setConstLabel(getLabels("Sales", "Sales Order"));

    //Product type's uuid for the filtered products in PI
    let product_type_id_list = [];
    setProdType({ ...prodType, loading: true, data: [] });
    getProductType().then(
      (res) => {
        if (res && res.data?.data?.length > 0) {
          const filterProductType = res.data.data.filter((item) =>
            [name_const.FINISHED_GOODS].includes(item.productType)
          );
          setProdType({ ...prodType, loading: false, data: filterProductType });
          product_type_id_list = JSON.stringify(
            filterProductType.map((item) => item.uuid)
          );
        } else {
          product_type_id_list = [];
          setProdType({ ...prodType, loading: false, data: [] });
        }
        fetchProdList(product_type_id_list);
      },
      (err) => {
        // Handle error if needed
        setProdType({ ...prodType, loading: false, data: [] });
      }
    );

    fetchLocations(def_org?.[0]?.uuid);
    fetchSOStatus();
  }, []);

  useEffect(() => {
    // console.log("main_obj", main_obj);
    async function loadData() {
      if (main_obj.so_data.length > 0 && main_obj.so_data?.[0]?.uuid) {
        const firstItem = main_obj.so_data[0]; // Get the first object

        // Fetch the location asynchronously and wait for the response
        const locObj = await singleLocation(firstItem?.location_id);

        setValue("customers", [
          {
            image_arr: imageFiles,
            sales_order_name: firstItem.sales_order_name || "",
            discount_type: discountType,
            location: locObj.length > 0 ? locObj : [], // Set fetched location
            sales_order_type: firstItem.sales_order_type || selectedType,
            order_date: firstItem.order_date
              ? new Date(firstItem.order_date)
              : null,
            approval_date: firstItem.approval_date
              ? new Date(firstItem.approval_date)
              : null,
            trail_date: firstItem.trail_date
              ? new Date(firstItem.trail_date)
              : null,
            delivery_date: firstItem.delivery_date
              ? new Date(firstItem.delivery_date)
              : null,
            final_date: firstItem.final_date
              ? new Date(firstItem.final_date)
              : null,
            discount:
              firstItem.so_order_lines?.length > 0
                ? Number(firstItem.so_order_lines[0]?.discount || 0.0).toFixed(2)
                : Number(0.0).toFixed(2),
            sub_total: Number(firstItem.sub_total || 0.0).toFixed(2),
            final_total: Number(firstItem.final_total || 0.0).toFixed(2),
            advance_amount: Number(firstItem.advance_amount || 0.0).toFixed(2),
            outstanding_amount: Number(
              firstItem.outstanding_amount || 0.0
            ).toFixed(2),
            internal_remark: firstItem.internal_remark,
            items:
              firstItem.so_order_lines?.length > 0
                ? firstItem.so_order_lines.map((line) => ({
                    product: [
                      {
                        ...line.product_id,
                        productWithPrice:
                          line.product_id?.productName &&
                          line.product_id?.productName !== ""
                            ? line.product_id?.productName +
                              " - " +
                              (line.product_id?.salesPrice || 0) +
                              " ₹"
                            : "",
                      },
                    ],
                    price: line.rate || line.product_id?.salesPrice,
                  }))
                : [{ product: [], price: "" }], // Default value if no order lines exist
          },
        ]);
      }
    }

    loadData();
  }, [main_obj.so_data, setValue]);

  async function singleLocation(loc_uuid) {
    const res = await getLocation({ uuid: loc_uuid });
    return res?.data?.data || [];
  }

  function fetchSOStatus() {
    getSOStatus().then(
      (res) => {
        if (res && res.data.data) {
          const result = res.data.data?.filter(
            (item) => item.status_name == name_const.APPROVED
          );
          set_approved_status({ data: result });
        } else {
          set_approved_status({ data: [] });
        }
      },
      (err) => {
        set_approved_status({ data: [] });
      }
    );
  }

  function fetchLocations(org_uuid) {
    setLoc_List({ ...loc_list, loading: true, data: [] });

    getLocation({ company_id: org_uuid }).then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          setLoc_List({ ...loc_list, loading: false, data: res.data.data });
        } else {
          setLoc_List({ ...loc_list, loading: false, data: [] });
        }
      },
      (err) => {
        setLoc_List({ ...loc_list, loading: true, data: [] });
      }
    );
  }

  function fetchProdList(prod_type_uuid) {
    setProd_List({ ...prod_List, loading: true, data: [] });

    getProdList({ product_type_id_list: prod_type_uuid }).then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          productWithPrice:
            item.productName !== null && item.productName !== ""
              ? item.productName + " - " + item.salesPrice + " ₹"
              : "",
        }));
        setProd_List({ ...prod_List, loading: false, data: result });
      },
      (err) => {
        setProd_List({ ...prod_List, loading: true, data: [] });
      }
    );
  }

  const goToPrevious = () => {
    const prevTab = parseInt(activeTab, 10) - 1;
    if (prevTab >= 0) {
      setActiveTab(prevTab.toString());
    }
  };

  
  const calculateTotalAmount = async (index, isFinalTotalChanged = false) => {
    const customerItems = getValues(`customers[${index}].items`) || [];
    let totalAmount = customerItems.reduce(
      (sum, item) => sum + (Number(item.price) || 0),
      0
    );
  
    let discType = getValues(`customers[${index}].discount_type`);
    let discountValue = Number(getValues(`customers[${index}].discount`)) || 0;
    let finalAmount = totalAmount;
  
    if (isFinalTotalChanged) {
      // When user manually changes final_total, adjust discount automatically
      let newFinalAmount = Number(getValues(`customers[${index}].final_total`));
  
      if (newFinalAmount > totalAmount) {
        setError(`customers[${index}].final_total`, {
          type: "manual",
          message: "Final amount cannot be greater than the subtotal.",
        });
        return;
      } else {
        clearErrors(`customers[${index}].final_total`);
      }
  
      // Change discount type to `disAmt` and calculate discount accordingly
      discType = "disAmt";
      discountValue = totalAmount - newFinalAmount;
  
      setValue(`customers[${index}].discount_type`, "disAmt");
      setValue(`customers[${index}].discount`, discountValue.toFixed(2), {
        shouldValidate: true,
      });
  
      finalAmount = newFinalAmount;
    } else {
      if (discType === "disPerc") {
        if (discountValue > 100) {
          setError(`customers[${index}].discount`, {
            type: "manual",
            message: "Percentage cannot exceed 100%",
          });
          return;
        } else {
          clearErrors(`customers[${index}].discount`);
        }
        finalAmount = totalAmount - totalAmount * (discountValue / 100);
      } else if (discType === "disAmt") {
        if (discountValue > totalAmount) {
          setError(`customers[${index}].discount`, {
            type: "manual",
            message: "Discount amount cannot exceed subtotal",
          });
          return;
        } else {
          clearErrors(`customers[${index}].discount`);
        }
        finalAmount = totalAmount - discountValue;
      }
    }
  
    finalAmount = Number(finalAmount.toFixed(2));
  
    let advanceAmount = Number(getValues(`customers[${index}].advance_amount`)) || 0;
    advanceAmount = Math.min(advanceAmount, finalAmount);
    let outstandingAmount = Math.max(finalAmount - advanceAmount, 0);
  
    setValue(`customers[${index}].sub_total`, totalAmount.toFixed(2));
  
    if (!isFinalTotalChanged) {
      setValue(`customers[${index}].final_total`, finalAmount.toFixed(2), {
        shouldValidate: true,
      });
    }
  
    setValue(`customers[${index}].outstanding_amount`, outstandingAmount.toFixed(2));
  
    // Ensure validation is triggered after value updates
    trigger(`customers[${index}].discount`);
  };
  
  async function onSubmit(data) {
    // console.log("data", data)
    const reqPayload = [];
    const payloadList = [];

    setLockBtn(true);

    for (let i = 0; i < data.customers?.length; i++) {
      let cst_data = cst_list[i];
      let discount_percentage = 0;
      const mes = cst_data.customer?.measurements?.filter(
        (ms) => ms.isSelected === true
      );

      if(data.customers[i]?.discount_type === "disAmt"){
        let difference = Number(data.customers[i]?.sub_total) - Number(data.customers[i]?.final_total);
        discount_percentage = (difference / Number(data.customers[i]?.sub_total)) * 100;
      } else {
        discount_percentage = Number(data.customers[i]?.discount);
      }

      for (let j = 0; j < data.customers?.[i].items?.length; j++) {
        const item = data.customers?.[i].items[j];

        const lineItems = {
          product_type: item.product?.[0]?.product_type_id?.productType,
          productname: item.product?.[0]?.productName,
          partnumber: item.product?.[0]?.OEMnumber,
          quantity: 1,
          rate: Number(item.price).toFixed(2),
          unit: item.product?.[0]?.unit_id?.uom,
          amount: Number(item.price).toFixed(2),
          product_type_id: item.product?.[0].product_type_id?.uuid,
          product_id: item.product?.[0]?.uuid,
          unit_id: item.product?.[0]?.unit_id?.uuid,
          org_id: def_org?.[0]?.id,
          gst_rate: 0,
          gst_amount: 0,
          discount: discount_percentage.toFixed(2),
          approval_date:
            data.customers?.[i]?.approval_date !== null &&
            data.customers?.[i]?.approval_date !== undefined
              ? convertDateYYMMDD(data.customers?.[i]?.approval_date, "-")
              : null,
          trail_date: convertDateYYMMDD(data.customers?.[i]?.trail_date, "-"),
          delivery_date: convertDateYYMMDD(
            data.customers?.[i]?.delivery_date,
            "-"
          ),
          final_date: convertDateYYMMDD(data.customers?.[i]?.final_date, "-"),
          measurement: mes?.[0]?.uuid,
          grouping_sequence: 1.0,
          party_id: cst_data.customer?.uuid,
        };

        reqPayload.push(lineItems);

        // if (item.price !== "0") {
        //   updateProduct({
        //     uuid: item.product?.[0]?.uuid,
        //     can_be_sold: "true",
        //     salesPrice: item.price,
        //   });
        // }
      }

      const payload = {
        org_id: def_org?.[0]?.uuid,
        customer_id: cst_data.customer?.uuid,
        customer_name: cst_data.customer?.organization, //as per discussion with tester change
        party_address: cst_data.customer?.address,
        party_po_ref: null,
        quote_ref: null,
        order_date: convertDateYYMMDD(data.customers?.[i]?.order_date, "-"),
        order_due_date: convertDateYYMMDD(
          data.customers?.[i]?.delivery_date,
          "-"
        ),
        approval_date:
          data.customers?.[i]?.approval_date !== null &&
          data.customers?.[i]?.approval_date !== undefined
            ? convertDateYYMMDD(data.customers?.[i]?.approval_date, "-")
            : null,
        trail_date: convertDateYYMMDD(data.customers?.[i]?.trail_date, "-"),
        delivery_date: convertDateYYMMDD(
          data.customers?.[i]?.delivery_date,
          "-"
        ),
        final_date: convertDateYYMMDD(data.customers?.[i]?.final_date, "-"),
        invoice_to: cst_data.customer?.uuid,
        invoice_to_address: cst_data.customer?.address,
        dispatch_to: cst_data.customer?.uuid,
        dispatch_to_address: cst_data.customer?.address,
        sub_total: Number(data.customers?.[i]?.sub_total).toFixed(2),
        final_total: Number(data.customers?.[i]?.final_total).toFixed(2),
        status: approved_status.data?.[0]?.id, // Approved status id
        internal_remark: data.customers?.[i]?.internal_remark,
        sales_order_name: data.customers?.[i]?.sales_order_name,
        igst: 0,
        igst_cost: 0,
        delivery_address: cst_data.customer?.address,
        sales_person: localData?.employee_id?.uuid,
        delivery_preferance: "",
        design_sheet: null,
        party_attribute_id: mes?.[0]?.uuid,
        employee_name:
          localData?.employee_id?.FirstName +
          " " +
          localData?.employee_id?.LastName,
        location_name: data.customers?.[i]?.location?.[0]?.locationname,
        location_id: data.customers?.[i]?.location?.[0]?.uuid,
        sales_order_type: data.customers?.[i]?.sales_order_type,
        age:
          cst_data.customer?.dob !== null
            ? calculateAge(cst_data.customer?.dob)
            : null,
        gender: cst_data.customer?.gender,
        kids: cst_data.customer?.kids_wear,
        advance_amount: Number(data.customers?.[i]?.advance_amount).toFixed(2),
        outstanding_amount: Number(
          data.customers?.[i]?.outstanding_amount
        ).toFixed(2),
      };

      payloadList.push({
        so_order: payload,
        so_order_lines: [...reqPayload], // Clone to avoid overwriting data for multiple customers
      });

      reqPayload.length = 0; // Reset for the next customer
    }

    // console.log("payloadList", payloadList); return;

    for (let k = 0; k < payloadList.length; k++) {
      createSalesOrder(payloadList[k]).then(
        async (res) => {
          if (res.status === 200 || res.status === 201) {

            for(let x = 0; x < payloadList[k]?.so_order_lines?.length; x++){
              const itm = payloadList[k]?.so_order_lines[x];
              if(itm.amount !== 0){
                await updateProduct({
                  uuid: itm.product_id,
                  can_be_sold: "true",
                  salesPrice: itm.amount,
                });
              }
            }

            const imgArr = [];
            for (let a = 0; a < data.customers?.[k]?.image_arr?.length; a++) {
              const imgObj = data.customers?.[k]?.image_arr[a];
              imgArr.push({
                fileName: imgObj?.file?.name?.replace(/[^a-zA-Z0-9.]+/g, ""),
                contentType: imgObj?.file?.type,
                model: {
                  ref_model_uuid: res.data?.so_order?.uuid,
                  model_name: screenList.sales_order_screen.replace(/\s+/g, ""),
                  name: imgObj?.file?.name?.replace(/[^a-zA-Z0-9.]+/g, ""),
                  document_type: imgObj?.file?.type.startsWith("image/")
                    ? "image"
                    : imgObj?.file?.type.startsWith("audio/")
                    ? "audio"
                    : "video",
                  created_by_user: localData?.user_id,
                  client_id: localData?.client_id,
                },
              });
            }

            const payload = {
              files: imgArr,
            };

            if (data.customers?.[k]?.image_arr.length > 0) {
              const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
              const idToken = localStorage.getItem("id");

              axios
                .post(url, payload, {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                  },
                })
                .then(
                  (res) => {
                    // console.log("res", res);
                    for (let x = 0; x < res.data.data?.body?.length; x++) {
                      const url2 = res.data.data?.body?.[x]?.url;

                      axios
                        .put(url2, data.customers?.[k]?.image_arr[x]?.file, {
                          headers: {
                            "Content-Type":
                              data.customers?.[k]?.image_arr[x]?.file?.type,
                          },
                        })
                        .then(
                          (ress) => {
                            toast.success(
                              "Sales order created successfully !",
                              {
                                autoClose: 2000,
                              }
                            );
                            setTimeout(() => {
                              navigate("/pcms/sales-order");
                              reset();
                            }, [2000]);
                            setLockBtn(false);
                          },
                          (errr) => {
                            console.log("errr", errr);
                            if (errr.response.status == 413) {
                              toast.error("Image upload failed !", {
                                autoClose: 3000,
                              });
                            }
                          }
                        );
                    }
                  },
                  (err) => {
                    console.log("err", err);
                  }
                );
            } else {
              toast.success("Sales order created successfully !", {
                autoClose: 2000,
              });
              setTimeout(() => {
                navigate("/pcms/sales-order");
                reset();
              }, [2200]);
              setLockBtn(false);
            }
          }
        },
        (err) => {
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
          setLockBtn(false);
        }
      );
    }
  }

  function onUpdateSubmit(data) {
    // console.log("data", data); 
    let reqPayload = [];

    setLockBtn(true);

    let updated_data = data.customers[0];
    let old_so = main_obj.so_data[0];
    let discount_percentage = 0;

    if(updated_data.discount_type === "disAmt"){
      let difference = Number(updated_data.sub_total) - Number(updated_data.final_total);
      discount_percentage = (difference / Number(updated_data.sub_total)) * 100;
    } else {
      discount_percentage = Number(updated_data.discount);
    }

    for (let i = 0; i < updated_data.items?.length; i++) {
      const item = updated_data.items[i];

      const lineItems = {
        uuid: old_so?.so_order_lines?.[i]?.uuid,
        product_type: item.product?.[0]?.product_type_id?.productType,
        productname: item.product?.[0]?.productName,
        partnumber: item.product?.[0]?.OEMnumber,
        quantity: old_so?.so_order_lines?.[i]?.quantity,
        rate: Number(item.price).toFixed(2),
        unit: item.product?.[0]?.unit_id?.uom,
        amount: Number(item.price).toFixed(2),
        product_type_id: item.product?.[0].product_type_id?.uuid,
        product_id: item.product?.[0]?.uuid,
        unit_id: item.product?.[0]?.unit_id?.uuid,
        org_id: old_so?.so_order_lines?.[i]?.org_id,
        gst_rate: 0,
        gst_amount: 0,
        discount: discount_percentage.toFixed(2),
        approval_date:
          updated_data?.approval_date !== null &&
          updated_data?.approval_date !== undefined
            ? convertDateYYMMDD(updated_data?.approval_date, "-")
            : null,
        trail_date: convertDateYYMMDD(updated_data?.trail_date, "-"),
        delivery_date: convertDateYYMMDD(updated_data?.delivery_date, "-"),
        final_date: convertDateYYMMDD(updated_data?.final_date, "-"),
        measurement: old_so?.so_order_lines?.[i]?.measurement?.uuid,
        grouping_sequence: old_so?.so_order_lines?.[i]?.grouping_sequence,
        party_id: old_so?.so_order_lines?.[i]?.party_id,
      };

      reqPayload.push(lineItems);

      // if (item.price !== "0") {
      //   updateProduct({
      //     uuid: item.product?.[0]?.uuid,
      //     can_be_sold: "true",
      //     salesPrice: item.price,
      //   });
      // }
    }

    const payload = {
      uuid: old_so?.uuid,
      org_id: old_so?.org_id?.uuid,
      customer_id: old_so?.customer_id,
      customer_name: old_so?.customer_name, //as per discussion with tester change
      party_address: old_so?.party_address,
      party_po_ref: null,
      quote_ref: null,
      order_date: convertDateYYMMDD(updated_data?.order_date, "-"),
      order_due_date: convertDateYYMMDD(updated_data?.delivery_date, "-"),
      approval_date:
        updated_data?.approval_date !== null &&
        updated_data?.approval_date !== undefined
          ? convertDateYYMMDD(updated_data?.approval_date, "-")
          : null,
      trail_date: convertDateYYMMDD(updated_data?.trail_date, "-"),
      delivery_date: convertDateYYMMDD(updated_data?.delivery_date, "-"),
      final_date: convertDateYYMMDD(updated_data?.final_date, "-"),
      invoice_to: old_so?.invoice_to?.uuid,
      invoice_to_address: old_so?.invoice_to?.address,
      dispatch_to: old_so?.dispatch_to?.uuid,
      dispatch_to_address: old_so?.dispatch_to?.address,
      sub_total: Number(updated_data?.sub_total).toFixed(2),
      final_total: Number(updated_data?.final_total).toFixed(2),
      status: old_so?.status?.id,
      internal_remark: updated_data?.internal_remark,
      sales_order_name: updated_data?.sales_order_name,
      igst: 0,
      igst_cost: 0,
      delivery_address: old_so?.delivery_address,
      // sales_person: localData?.employee_id?.uuid,
      delivery_preferance: "",
      design_sheet: null,
      party_attribute_id: old_so?.party_attribute_id,
      employee_name:
        localData?.employee_id?.FirstName +
        " " +
        localData?.employee_id?.LastName,
      location_name: updated_data?.location?.[0]?.locationname,
      location_id: updated_data?.location?.[0]?.uuid,
      sales_order_type: updated_data?.sales_order_type,
      // age:
      //   cst_data.customer?.dob !== null
      //     ? calculateAge(cst_data.customer?.dob)
      //     : null,
      // gender: cst_data.customer?.gender,
      // kids: cst_data.customer?.kids_wear,
      advance_amount: Number(updated_data?.advance_amount).toFixed(2),
      outstanding_amount: Number(updated_data?.outstanding_amount).toFixed(2),
    };

    const updatePayload = {
      so_order: payload,
      so_order_lines: reqPayload,
    };

    // console.log("updatePayload", updatePayload);

    updateSalesOrder(updatePayload).then(
      async (res) => {
        if (res.status === 200 || res.status === 201) {

          for(let x = 0; x < updatePayload?.so_order_lines?.length; x++){
            const itm = updatePayload?.so_order_lines[x];
            if(itm.amount !== 0){
              await updateProduct({
                uuid: itm.product_id,
                can_be_sold: "true",
                salesPrice: itm.amount,
              });
            }
          }

          const imgArr = [];
          for (let a = 0; a < updated_data?.image_arr?.length; a++) {
            const imgObj = updated_data?.image_arr[a];
            imgArr.push({
              fileName: imgObj?.file?.name?.replace(/[^a-zA-Z0-9.]+/g, ""),
              contentType: imgObj?.file?.type,
              model: {
                ref_model_uuid: old_so?.uuid,
                model_name: screenList.sales_order_screen.replace(/\s+/g, ""),
                name: imgObj?.file?.name?.replace(/[^a-zA-Z0-9.]+/g, ""),
                document_type: imgObj?.file?.type.startsWith("image/")
                  ? "image"
                  : imgObj?.file?.type.startsWith("audio/")
                  ? "audio"
                  : "video",
                created_by_user: localData?.user_id,
                client_id: localData?.client_id,
              },
            });
          }

          const payload = {
            files: imgArr,
          };

          if (updated_data?.image_arr.length > 0) {
            const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
            const idToken = localStorage.getItem("id");

            axios
              .post(url, payload, {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  "Content-Type": "application/json",
                },
              })
              .then(
                (res) => {
                  // console.log("res", res);
                  for (let x = 0; x < res.data.data?.body?.length; x++) {
                    const url2 = res.data.data?.body?.[x]?.url;

                    axios
                      .put(url2, updated_data?.image_arr[x]?.file, {
                        headers: {
                          "Content-Type":
                            updated_data?.image_arr[x]?.file?.type,
                        },
                      })
                      .then(
                        (ress) => {
                          toast.success("Sales order updated successfully !", {
                            autoClose: 2000,
                          });
                          setTimeout(() => {
                            navigate("/pcms/sales-order");
                            reset();
                          }, [2000]);
                          setLockBtn(false);
                        },
                        (errr) => {
                          console.log("errr", errr);
                          if (errr.response.status == 413) {
                            toast.error("Image upload failed !", {
                              autoClose: 3000,
                            });
                          }
                        }
                      );
                  }
                },
                (err) => {
                  console.log("err", err);
                }
              );
          } else {
            toast.success("Sales order updated successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/sales-order");
              reset();
            }, [2200]);
            setLockBtn(false);
          }
        }
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setLockBtn(false);
      }
    );
  }

  const updateCustomerImages = (index, newFiles) => {
    const customers = getValues("customers"); // Get all customers
    customers[index].image_arr = newFiles; // Update specific customer’s files
    setValue("customers", [...customers]); // Update form state
  };

  const updateCustomerDiscountType = (index, disc_type) => {
    const customers = getValues("customers"); // Get all customers
    customers[index].discount_type = disc_type; // Update specific customer's discount type
    setValue("customers", [...customers]); // Update form state
  };

  return (
    <React.Fragment>
      <Add_Product
        fetchProdList={fetchProdList}
        prodType={prodType}
        newProduct={newProduct}
        gender={gender}
        setVisible={setVisible}
        visible={visible}
      />

      <form onSubmit={(e) => e.preventDefault()}>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          {fields.map((customer, index) => (
            <Tab
              eventKey={index.toString()}
              title={
                cst_list[index]?.customer?.organization ||
                main_obj.so_data[index]?.customer_name
              }
              key={customer.id}
              disabled={index !== parseInt(activeTab, 10)}
            >
              <div className="card p-3 shadow-sm rounded">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_sales_order_name
                          ? constLabel.lbl_sales_order_name
                          : "Sales Order Name"}
                      </label>
                      <input
                        className="form-control mt-2"
                        id="sales_order_name"
                        name="sales_order_name"
                        type="text"
                        placeholder="Enter sales order name"
                        {...register(`customers[${index}].sales_order_name`)}
                      />
                      {/* {errors.customers?.[index]?.sales_order_name && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.sales_order_name.message}
                        </span>
                      )} */}
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-group typeahead-container">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_location
                          ? constLabel.lbl_location
                          : "Location"}
                        {""}
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        name={`customers[${index}].location`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            className="mt-2"
                            id="basic-typeahead-single"
                            labelKey="locationname"
                            multiple={false}
                            options={loc_list.data}
                            isLoading={loc_list.loading}
                            placeholder="Select location"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.organization ? true : false}
                            disabled={
                              main_obj.so_data[index]?.uuid ? true : false
                            }
                          />
                        )}
                      />
                      {!loc_list.loading &&
                        !errors.customers?.[index]?.location && (
                          <span className="arrow-icon"></span>
                        )}
                      {errors.customers?.[index]?.location && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.location.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_order_date
                          ? constLabel.lbl_order_date
                          : "Order Date"}
                        {""}
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        control={control}
                        name={`customers[${index}].order_date`}
                        defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.order_date
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            placeholderText="Select date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            defaultValue={new Date()}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            disabled={
                              main_obj.so_data[index]?.uuid ? true : false
                            }
                          />
                        )}
                      />

                      {errors.customers?.[index]?.order_date && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.order_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_approval_date
                          ? constLabel.lbl_approval_date
                          : "Approval Date"}
                        {""}
                        {/* <span className="text-danger"> *</span> */}
                      </label>
                      <Controller
                        control={control}
                        name={`customers[${index}].approval_date`}
                        defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.approval_date
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            placeholderText="Select approval date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            defaultValue={new Date()}
                            // maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />

                      {errors.customers?.[index]?.approval_date && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.approval_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_trial_date
                          ? constLabel.lbl_trial_date
                          : "Trial Date"}
                        {""}
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        control={control}
                        name={`customers[${index}].trail_date`}
                        defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.trail_date
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            placeholderText="Select trial date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            defaultValue={new Date()}
                            // maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />

                      {errors.customers?.[index]?.trail_date && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.trail_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_delivery_date
                          ? constLabel.lbl_delivery_date
                          : "Delivery Date"}
                        {""}
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        control={control}
                        name={`customers[${index}].delivery_date`}
                        defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.delivery_date
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            placeholderText="Select delivery date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            defaultValue={new Date()}
                            // maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />

                      {errors.customers?.[index]?.delivery_date && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.delivery_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_final_date
                          ? constLabel.lbl_final_date
                          : "Final Date"}
                        {""}
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        control={control}
                        name={`customers[${index}].final_date`}
                        defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.final_date
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            placeholderText="Select final date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            defaultValue={new Date()}
                            // maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />

                      {errors.customers?.[index]?.final_date && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.final_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_special_instruction
                          ? constLabel.lbl_special_instruction
                          : "Special Instruction"}{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <textarea
                        className={
                          errors.internal_remark
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        id="internal_remark"
                        name="internal_remark"
                        type="text"
                        placeholder="Enter special instructions"
                        {...register(`customers[${index}].internal_remark`)}
                      ></textarea>
                      {errors.customers?.[index]?.internal_remark && (
                        <span className="text-danger err-msg">
                          {errors.customers?.[index]?.internal_remark.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <div className="form-group row">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_order_type
                          ? constLabel.lbl_order_type
                          : "Order Type"}
                      </label>

                      <div className="col-md-12 mt-2">
                        {selectedType == "Alteration" ? (
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              id="Alteration"
                              value="Alteration"
                              checked={true} // Always checked when Alteration is selected
                              disabled={true} // Prevent user from changing it
                            />
                            <label
                              className="form-check-label mt-1"
                              htmlFor="Alteration"
                            >
                              Alteration
                            </label>
                          </div>
                        ) : (
                          ["New", "Collection", "OTR", "Online"].map(
                            (option) => (
                              <div
                                className="form-check form-check-inline"
                                key={option}
                              >
                                <input
                                  className="form-check-input mt-2"
                                  type="checkbox"
                                  id={option}
                                  value={option}
                                  checked={selectedType === option} // Only one selection allowed
                                  onChange={() => {
                                    setSelectedType(option); // Set the new selection
                                  }}
                                  disabled={
                                    main_obj.so_data[index]?.uuid ? true : false
                                  }
                                />
                                <label
                                  className="form-check-label mt-1"
                                  htmlFor={option}
                                >
                                  {option}
                                </label>
                              </div>
                            )
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-3 text-end">
                    <button
                      type="button"
                      className="btn btn-warning mt-3"
                      onClick={() => setVisibleIndex(index)} // Open modal for this customer
                    >
                      {constLabel?.lbl_design_sheet
                        ? constLabel?.lbl_design_sheet
                        : "Design Sheet"}
                    </button>
                  </div>

                  {/* Show modal only for the selected customer */}
                  {visibleIndex === index && (
                    <DesignSheetUpload
                      imageFiles={getValues(`customers[${index}].image_arr`)}
                      setImageFiles={(newFiles) =>
                        updateCustomerImages(index, newFiles)
                      }
                      visible={visibleIndex === index}
                      setVisible={() => setVisibleIndex(null)} // Close modal
                    />
                  )}

                  <hr className="my-2" />

                  <div className="col-12 col-md-6 p-2 border">
                    <p className="fw-bold mt-2">Order Items</p>
                    <hr />
                    <div className="">
                      <table className="table table-responsive table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">
                              {constLabel?.lbl_product_name
                                ? constLabel.lbl_product_name
                                : "Product Name"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_unit_price
                                ? constLabel.lbl_unit_price
                                : "Unit Price"}{" "}
                              <span>&#8377;</span>
                            </th>
                            {main_obj.so_data[index]?.uuid ? null : <th scope="col">{"Action"}</th> }
                          </tr>
                        </thead>
                        <tbody>
                          {(main_obj.so_data?.[0]?.uuid
                            ? main_obj.so_data?.[0]?.so_order_lines
                            : itemFields
                          ).map((item, itemIndex) => (
                            <tr key={item.id}>
                              <td style={{ verticalAlign: "top" }}>
                                <Controller
                                  name={`customers[${index}].items[${itemIndex}].product`}
                                  control={control}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <Typeahead
                                      id="basic-typeahead-single"
                                      labelKey="productWithPrice"
                                      size="sm"
                                      multiple={false}
                                      options={prod_List.data}
                                      isLoading={prod_List.loading}
                                      placeholder="Select product"
                                      onChange={(selected) => {
                                        if (selected.length > 0) {
                                          setValue(
                                            `customers[${index}].items[${itemIndex}].product`,
                                            selected,
                                            { shouldValidate: true }
                                          );
                                          setValue(
                                            `customers[${index}].items[${itemIndex}].price`,
                                            selected[0].salesPrice ?? 0,
                                            { shouldValidate: true }
                                          );
                                          calculateTotalAmount(index);
                                        } else {
                                          setValue(
                                            `customers[${index}].items[${itemIndex}].product`,
                                            [],
                                            {
                                              shouldValidate: true,
                                            }
                                          );
                                          setValue(
                                            `customers[${index}].items[${itemIndex}].price`,
                                            "",
                                            {
                                              shouldValidate: true,
                                            }
                                          );
                                          calculateTotalAmount(index);
                                        }

                                        const isNewOption =
                                          selected && selected[0]?.customOption;
                                        if (isNewOption) {
                                          const newItem =
                                            selected[0]?.productWithPrice;
                                            setNewProduct(newItem);
                                          setGender(
                                            main_obj.so_data[index]?.customer
                                              ?.gender
                                          );
                                          setTimeout(() => {
                                            setVisible(true);
                                            
                                          }, 50);
                                        }
                                        onChange(selected);
                                      }}
                                      newSelectionPrefix="Add New: "
                                      allowNew
                                      onBlur={onBlur}
                                      selected={value}
                                      isInvalid={
                                        errors?.customers?.[index]?.items?.[
                                          itemIndex
                                        ]?.product
                                          ? true
                                          : false
                                      }
                                      disabled={
                                        main_obj.so_data[index]?.uuid
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                                {errors?.customers?.[index]?.items?.[itemIndex]
                                  ?.product && (
                                  <span className="text-danger err-msg">
                                    {
                                      errors?.customers?.[index]?.items?.[
                                        itemIndex
                                      ]?.product.message
                                    }
                                  </span>
                                )}

                                {main_obj.so_data[index]
                                  ?.uuid ? null : itemIndex + 1 ==
                                  itemFields.length ? (
                                  <div>
                                    <button
                                      style={{ fontSize: "16px" }}
                                      className="btn btn-sm button-primary px-2 mt-2"
                                      onClick={() => {
                                        appendItem({
                                          product: [],
                                          price: 0.0,
                                        });

                                        calculateTotalAmount(index);
                                      }}
                                      disabled={
                                        main_obj.so_data[index]?.uuid
                                          ? true
                                          : false
                                      }
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          color: "#ffffff",
                                          marginRight: "5px",
                                        }}
                                        icon={faAdd}
                                        size="sm"
                                      />{" "}
                                      {constLabel?.lbl_add
                                        ? constLabel.lbl_add
                                        : "Add"}
                                    </button>
                                  </div>
                                ) : null}
                              </td>

                              <td
                                style={{ verticalAlign: "top", width: "20%" }}
                              >
                                <input
                                  className={
                                    errors?.customers?.[index]?.items?.[
                                      itemIndex
                                    ]?.price
                                      ? "form-control form-control-sm is-invalid"
                                      : "form-control form-control-sm"
                                  }
                                  type="text"
                                  // defaultValue={0}
                                  name={`customers[${index}].items[${itemIndex}].price`}
                                  {...register(
                                    `customers[${index}].items[${itemIndex}].price`,
                                    {
                                      onChange: () =>
                                        calculateTotalAmount(index),
                                    }
                                  )}
                                />

                                {errors?.customers?.[index]?.items?.[itemIndex]
                                  ?.price && (
                                  <span className="text-danger err-msg">
                                    {
                                      errors?.customers?.[index]?.items?.[
                                        itemIndex
                                      ]?.price.message
                                    }
                                  </span>
                                )}
                              </td>
                            {main_obj.so_data[index]?.uuid ? null :
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "top",
                                  width: "10%",
                                }}
                              >
                                <Tooltip
                                  id={"delete-tooltip" + index}
                                  place="top"
                                />
                                {
                                  <div
                                    data-tooltip-id={"delete-tooltip" + index}
                                    data-tooltip-content={
                                      constLabel?.lbl_delete_product
                                        ? constLabel.lbl_delete_product
                                        : "Delete Product Item"
                                    }
                                    className="deleteIcon cursor_pointer"
                                    onClick={
                                      itemIndex > 0
                                        ? () => {
                                            removeItem(itemIndex);
                                            calculateTotalAmount(index);
                                          }
                                        : null
                                    }
                                  >
                                    <Trash2 color="#FFFFFF" size={18} />
                                  </div>
                                }
                              </td>
                            }
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 p-2 border">
                    <p className="fw-bold mt-2">Payment Details</p>
                    <hr />
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_amount
                              ? constLabel.lbl_amount
                              : "Amount"}
                          </label>
                          <input
                            className="form-control mt-2"
                            id="sub_total"
                            name={`customers[${index}].sub_total`}
                            type="text"
                            {...register(`customers[${index}].sub_total`)}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group row">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_discount_type
                              ? constLabel.lbl_discount_type
                              : "Discount Type"}
                          </label>

                          <div className="col-md-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="radio"
                                name={`discountType_${index}`} // Unique name per customer
                                id={`disPerc_${index}`}
                                value="disPerc"
                                checked={
                                  getValues(
                                    `customers[${index}].discount_type`
                                  ) === "disPerc"
                                } // FIXED
                                {...register(
                                  `customers[${index}].discount_type`,
                                  {
                                    onChange: (e) => {
                                      updateCustomerDiscountType(
                                        index,
                                        e.target.value
                                      );
                                      calculateTotalAmount(index);
                                    },
                                  }
                                )}
                              />
                              <label
                                className="form-check-label body2"
                                htmlFor={`disPerc_${index}`}
                              >
                                Percentage (%)
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="radio"
                                name={`discountType_${index}`} // Unique name per customer
                                id={`disAmt_${index}`}
                                value="disAmt"
                                checked={
                                  getValues(
                                    `customers[${index}].discount_type`
                                  ) === "disAmt"
                                } // FIXED
                                {...register(
                                  `customers[${index}].discount_type`,
                                  {
                                    onChange: (e) => {
                                      updateCustomerDiscountType(
                                        index,
                                        e.target.value
                                      );
                                      calculateTotalAmount(index);
                                    },
                                  }
                                )}
                              />
                              <label
                                className="form-check-label body2"
                                htmlFor={`disAmt_${index}`}
                              >
                                Amount (₹)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_discount
                              ? constLabel.lbl_discount
                              : "Discount"}{" "}
                            {getValues(`customers[${index}].discount_type`) ===
                            "disPerc"
                              ? "(%)"
                              : "(₹)"}
                          </label>
                          <input
                              className={`form-control mt-2 ${errors?.customers?.[index]?.discount ? "is-invalid" : ""}`}
                              id={`discount_${index}`}
                              name={`customers[${index}].discount`}
                              type="text"
                              {...register(`customers[${index}].discount`, {
                                onChange: (e) => {
                                  clearTimeout(debounceTimer);
                                  const newTimer = setTimeout(() => {
                                    calculateTotalAmount(index);
                                  }, 500); // 500ms debounce
                                  setDebounceTimer(newTimer);
                                },
                              })}
                            />
                          {errors.customers?.[index]?.discount && (
                            <small className="text-danger err-msg">
                              {errors.customers[index].discount.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 mt-3">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_total_amount
                              ? constLabel.lbl_total_amount
                              : "Total Amount"}
                          </label>
                          <input
                            className={`form-control mt-2 ${errors?.customers?.[index]?.final_total ? "is-invalid" : ""}`}
                            id="final_total"
                            name={`customers[${index}].final_total`}
                            type="text"
                            {...register(`customers[${index}].final_total`, {
                              onChange: (e) => {
                                clearTimeout(debounceTimer);
                                const newTimer = setTimeout(() => {
                                  calculateTotalAmount(index, true);
                                }, 500); // 500ms debounce
                                setDebounceTimer(newTimer);
                              },
                            })}
                          />
                          {errors.customers?.[index]?.final_total && (
                            <small className="text-danger err-msg">
                              {errors.customers[index].final_total.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 mt-3">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_advance_amount
                              ? constLabel.lbl_advance_amount
                              : "Advance Amount"}
                          </label>
                          <input
                            className="form-control mt-2"
                            id="advance_amount"
                            name="advance_amount"
                            type="text"
                            {...register(`customers[${index}].advance_amount`, {
                              onChange: () => calculateTotalAmount(index),
                            })}
                          />
                          {errors.customers?.[index]?.advance_amount && (
                            <small className="text-danger err-msg">
                              {errors.customers[index].advance_amount.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 mt-3">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_outstanding_amount
                              ? constLabel.lbl_outstanding_amount
                              : "Outstanding Amount"}
                          </label>
                          <input
                            className="form-control mt-2"
                            id="outstanding_amount"
                            name="outstanding_amount"
                            type="text"
                            {...register(
                              `customers[${index}].outstanding_amount`
                            )}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          ))}
        </Tabs>

        <div className="d-flex justify-content-between mt-2">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={goToPrevious}
            disabled={activeTab === "0"}
          >
            Previous
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={validateAndNext}
            disabled={parseInt(activeTab, 10) + 1 >= fields.length && lockBtn}
          >
            {parseInt(activeTab, 10) + 1 < fields.length ? "Next" : "Submit"}
            {parseInt(activeTab, 10) + 1 >= fields.length && lockBtn ? (
              <Spinner animation="border" size="sm" className="ms-2" />
            ) : null}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Step_Two;
