import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  get_store,
  getInternalCompany,
  get_material_code,
  getSupplier,
  getProdList,
  create_pi_wo_po,
  getProductType,
  get_product_light,
} from "../../../api";
import {
  convertDateYYMMDD,
  getLabels,
  convertDate,
  incrementMaterialCode,
} from "../../../common/Functions/CommonFunctions";
import { useNavigate } from "react-router-dom";
import CreateRMI from "./RMI-WOPO/CreateRMI";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import info from "../../../assets/images/info.png";
import * as name_const from "../../../common/Constants/constants";
import { s3_image_upload, supplier } from "../../../constant";
import { screenList } from "../../../common/Labels/screenList";
import axios from "axios";
const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = yup
  .object()
  .shape({
    // store: yup
    //   .array()
    //   .min(1, "Please select store !")
    //   .required("Please select store !"),
    invoiceTo: yup
      .array()
      .min(1, "Please select invoice to !")
      .required("Please select invoice to !"),
    dispatchTo: yup
      .array()
      .min(1, "Please select dispatch to !")
      .required("Please select dispatch to !"),
    supplier: yup
      .array()
      .min(1, "Please select supplier / customer !")
      .required("Please select supplier / customer !"),
    location: yup
      .array()
      .min(1, "Please select location !")
      .required("Please select location !"),
    // tally_voucher_no: yup
    //   .string()
    //   .required("Enter tally voucher no / requisition no !"),
    // tally_voucher_date: yup
    //   .date()
    //   .typeError("Please select tally voucher date !")
    //   .required("Please select tally voucher date !"),
    invoice_no: yup.string().required("Invoice no is required !"),
    invoice_date: yup
      .date()
      .typeError("Please select invoice date !")
      .required("Please select invoice date !"),
    pi_image: yup
      .mixed()
      .test("fileSize", "Image can be uploaded upto 10 MB", (value) => {
        if (value.length == 0) return true;
        return value && value[0]?.size <= 10 * 1024 * 1024;
      })
      .test("fileType", "Only image files are allowed", (value) => {
        if (value.length == 0) return true; // If no file is selected, it's considered valid.
        // return value[0]?.type.startsWith('image/');
        return imageFileTypes.includes(value[0]?.type);
      }),
    items: yup.array().of(
      yup.object().shape({
        product: yup
          .array()
          .min(1, "Please select product !")
          .required("Please select product !"),
        store: yup
          .array()
          .min(1, "Please select store !")
          .required("Please select store !"),
        material_code: yup
          .string()
          .when(["batch_tracking"], (batchTracking, schema) => {
            return batchTracking === true
              ? schema.required("Batch code is required!")
              : schema;
          })
          .nullable(),
        received_qty: yup
            .string()
            .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
            .required("Please enter received quantity!"),
        amount: yup
            .string()
            .matches(/^\d+(\.\d+)?$/, "Enter valid amount")
            .required("Please enter received amount!"),
      })
    ),
  })
  .required();

function PIWithoutPO(props) {
  //   const {poList, setPOList} = props;
  let navigate = useNavigate();
  const rmi_config = JSON.parse(localStorage.getItem("configuration"));
  const localData = JSON.parse(localStorage.getItem("localData"));
  const [purchaseStore, setPurchaseStore] = useState({
    data: [],
    loading: false,
  });
  const [storeList, setStoreList] = useState({ data: [], loading: false });
  const [loc_name, setLoc_Name] = useState({ data: [], loading: false });
  const [lock, setLock] = useState(false); // state used for save button disabled if store_type is not "Purchase"
  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);

  const [rmiModal, setRMIModal] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [rmi_data, setRMI_Data] = useState([]);

  const [mat_code, setMat_Code] = useState();

  const [internalCompList, setInternalComp] = useState({
    data: [],
    loading: false,
  });
  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });

  const [def_store, setDef_Store] =  useState([]);

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          product: [],
          rate: 0,
          received_qty: 0,
          amount: 0,
          remark: "",
        },
      ],
    },
  }); // initialise the hook

  let { errors } = formState;
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "items",
  });

  // when component load then run below useEffect and call the fuctions inside useEffeact
  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Purchase Inward"));
    fetchMaterialCode();
    fetchInternalComp();
    fetchSuppliers();
    //Product type's uuid for the filtered products in PI
    let product_type_id_list = [];

    getProductType().then(
      (res) => {
        if (res && res.data?.data?.length > 0) {
          const filteredProducts = res.data.data.filter((item) =>
            [name_const.GENERAL_ITEM, name_const.RAW_MATERIAL, name_const.CONSUMABLE_ITEMS, name_const.TOOLS, name_const.GAUGES, name_const.FIXTURES].includes(item.productType)
          );

          product_type_id_list = JSON.stringify(
            filteredProducts.map((item) => item.uuid)
          );
        } else {
          product_type_id_list = [];
        }
        fetchProdList(product_type_id_list);
      },
      (err) => {
        // Handle error if needed
      }
    );
  }, []);

  const selLoc = watch("location");

  function fetchStore(id) {
    setPurchaseStore({ ...purchaseStore, data: [], loading: true });
    setStoreList({ ...storeList, data: [], loading: true });

    get_store({ location_id: id }).then(
      (res) => {
        const purch_store = res.data.data.filter(
          (store) => store.name == name_const.PURCHASE
        );

        setDef_Store(purch_store);
        // console.log("purch_store", purch_store);
        setStoreList({ ...storeList, data: res.data.data, loading: false });

        const line_items = getValues("items");
        // console.log("line_items", line_items);

        // const new_items = [];
        let i = 0;
        for (const item of line_items) {
          // console.log(item);

          // const new_item = { ...item };
          if (item.store?.length == 0) {
            setValue(`items[${i}].store`, purch_store, {
              shouldValidate: true,
            });
            // console.log("purch_store", purch_store);
          } else if (item.store == undefined) {
            setValue(`items[${i}].store`, purch_store, {
              shouldValidate: true,
            });
          }
          i++;
          // new_items.push(new_item);
        }

        if (purch_store.length > 0) {
          setPurchaseStore({
            ...purchaseStore,
            data: purch_store,
            loading: false,
          });
        } else {
          toast.error("Purchase store is not present for this location", {
            autoClose: 3000,
          });
          setLock(true);
        }
      },
      (err) => {
        setStoreList({ ...storeList, data: [], loading: false });
        setPurchaseStore({ ...purchaseStore, data: [], loading: false });
      }
    );
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, loading: true, data: [] });
    getSupplier().then(
      (res) => {
        const result = res.data?.data?.filter((item) => item.organization !== null || item.name !== null);
        const parties = result
          .filter(
            (item) =>
              item.partyType.trim().toUpperCase() === name_const.SUPPLIER.trim().toUpperCase() ||
              item.partyType.trim().toUpperCase() === name_const.CUSTOMER.trim().toUpperCase()
          )
          .map((item) => ({
            ...item,
            partyname:
              item.partyType.trim().toUpperCase() === name_const.SUPPLIER.trim().toUpperCase()
                ? item.name
                : item.organization,
          }));
        setSupplierList({
          ...supplierList,
          loading: false,
          data: parties,
        });
      },
      (err) => {
        //
        setSupplierList({ ...supplierList, loading: false, data: [] });
        // toast.error("Something went wrong in suppliers!", {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function fetchInternalComp() {
    setInternalComp({ ...internalCompList, data: [], loading: true });

    getInternalCompany({
      with_location: 1,
    }).then(
      (res) => {
        const def_org_obj = localData?.organizations?.filter(
          (item) => item.default_org == true
        );
        const ress = res.data?.data?.filter(
          (item) => item.uuid == def_org_obj?.[0]?.uuid
        );

        if (ress && ress.length > 0) {
          setValue("invoiceTo", ress);
          setValue("dispatchTo", ress);
          setLoc_Name({
            ...loc_name,
            data: ress?.[0]?.location_list,
            loading: false,
          });
        } else {
          setValue("invoiceTo", []);
          setValue("dispatchTo", []);
          setLoc_Name({ ...loc_name, data: [], loading: false });
        }

        setInternalComp({
          ...internalCompList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setInternalComp({ ...internalCompList, data: [], loading: false });
      }
    );
  }

  function fetchProdList(prod_type_uuid) {
    setProd_List({ ...prod_List, loading: true, data: [] });

    get_product_light({product_type_id_list: prod_type_uuid }).then(
      (res) => {
        const arr = res.data.data.map((item) => ({
          ...item,
          productNameWithPart:
            item.productName && item.productName !== ""
              ? item.partNo && item.partNo !== ""
                ? item.productName + " - " + item.partNo
                : item.productName
              : "",
        }));
        setProd_List({ ...prod_List, loading: false, data: arr });
      },
      (err) => {
        setProd_List({ ...prod_List, loading: true, data: [] });
      }
    );
  }

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      if (file) {
        reader.readAsDataURL(file);
      } else {
        reject(new Error("File is empty"));
      }
    });
  }

  const onSubmit = async (data) => {
    console.log("rmi_data", rmi_data);
    console.log("data", data);
    const rmiInwardCount = data?.items?.filter(
      (item) =>
        item.product?.[0]?.product_type_id?.productType === "Raw material" &&
        item.product?.[0]?.material_type_id &&
        ["BAR", "CASTING", "FORGING"].includes(item.product?.[0]?.material_type_id?.material_type)
    );

    if (rmiInwardCount.length !== rmi_data.length) {
      toast.error("Please fill raw material inward details first !", {
        autoClose: 2000,
      });
      return;
    }

    let reqPayload = [];

    if (lock) {
      return;
    }

    setLockBtn(true);

    for (let i = 0; i < data.items.length; i++) {
      //if po_order_qty and inward qty both are same then not create lineItems payload
      let rmi_obj = rmi_data.find((rmi) => rmi.ind === i);

      const lineItems = {
        store_uuid: data.items[i].store[0]?.uuid,
        store_name: data.items[i].store[0]?.name,
        productid_id: data.items[i].product?.[0]?.uuid,
        productname: data.items[i].product?.[0]?.productName,
        received_qty: parseFloat(data.items[i].received_qty),
        amount: parseFloat(data.items[i].amount),
        unit: data.items[i].unit.trim(), // As per discussio backend devloper key added 30-11-2023
        remark: data.items[i].remark ? data.items[i].remark?.trim() : "",
        invoice_no: data.invoice_no ?? 0,
        invoice_qty: parseFloat(data.items[i].received_qty),
        invoice_date: convertDateYYMMDD(data.invoice_date, "-"), //"2023-01-01",
        prodType: data.items[i].product?.[0]?.product_type_id?.productType,
        prodId: data.items[i].product?.[0]?.productId,
        materialType:
          data.items[i]?.product?.[0]?.material_type_id?.material_type ?? null,
        diameter: data.items[i]?.product?.[0]?.diameter,
        material_code: data.items?.[i]?.material_code,

        po_order_qty: 0,
        prev_inward: 0,
        remain_qty: 0,
        poproductid: null,
      };

      // if (
      //   data.items[i]?.product?.[0]?.material_type_id?.material_type !== null &&
      //   data.items[i]?.product?.[0]?.material_type_id?.material_type == "BAR" &&
      //   rmi_obj
      // ) {
      //   delete rmi_obj.ind;
      //   lineItems.RMI = rmi_obj;
      // }

      if (rmi_obj) {
        delete rmi_obj.ind;
        lineItems.RMI = rmi_obj;
      }

      reqPayload.push(lineItems);
    }

    // If product items order qty and inwarded qty both are same then below block run
    if (reqPayload.length == 0) {
      SweetAlert.fire({
        title: "Product items already inwarded !",
        icon: "warning",
        confirmButtonText: "Ok",
        reverseButtons: true,
      });
      setLockBtn(false);
      return;
    }

    if (purchaseStore.data.length == 0) {
      toast.error("Stores not found", {
        autoClose: 3000,
      });
    }
    const purchase_store = purchaseStore.data.filter(
      (val) => val.name.trim().toUpperCase() == (name_const.PURCHASE).trim().toUpperCase()
    );
    if (purchase_store.length == 0) {
      toast.error("Purchase store not found", {
        autoClose: 3000,
      });
    }

    const payload = {
      invoice_to_name: data.invoiceTo[0]?.comName,
      dispatch_to_name: data.dispatchTo[0]?.comName,
      invoice_to_id: data.invoiceTo[0]?.uuid,
      dispatch_to_id: data.dispatchTo[0]?.uuid,
      supplier_id: data.supplier[0].uuid,
      supplier_name: data.supplier[0].partyname,
      invoice_no: data.invoice_no,
      invoice_date: convertDateYYMMDD(data.invoice_date, "-"),
      location_id:
        selLoc !== undefined && selLoc.length > 0 ? selLoc?.[0]?.uuid : null,
      location_name:
        selLoc !== undefined && selLoc.length > 0
          ? selLoc?.[0]?.locationname
          : null,
      store_id:
        purchaseStore.data !== undefined && purchaseStore.data.length > 0
          ? purchase_store[0]?.uuid
          : null,
      store_name:
        purchaseStore.data !== undefined && purchaseStore.data.length > 0
          ? purchase_store[0]?.name
          : null,
      tally_voucher_no:
        data.tally_voucher_no !== undefined && data.tally_voucher_no !== ""
          ? data.tally_voucher_no
          : "0",
      tally_voucher_date:
        data.tally_voucher_date !== undefined &&
        data.tally_voucher_date !== null
          ? convertDateYYMMDD(data.tally_voucher_date, "-")
          : null,
      voucher_no: data?.voucher_no,
      po_inward_line_item: reqPayload,

      po_remark: null,
      po_date: convertDateYYMMDD(new Date(), "-"),
    };

    Object.keys(payload).forEach((key) => {
      // Check if the value is null and delete the key
      if (payload[key] === null) {
        delete payload[key];
      }
    });

    // console.log("payload", payload); return;

    create_pi_wo_po(payload).then(
      (res) => {
        if(res.status === 200 || res.status === 201){
          const imgArr = [{
              fileName: (data.pi_image?.[0]?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
              contentType: data.pi_image?.[0]?.type,
              model: {
                ref_model_uuid: res.data?.po_inward?.uuid,
                model_name: screenList.purchase_inward_screen.replace(/\s+/g, ""),
                name: (data.pi_image?.[0]?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
                document_type: data.pi_image?.[0]?.type.startsWith("image/") ? "image" : "image",
                created_by_user: localData?.user_id,
                client_id: localData?.client_id
              }
            }];
         
          const img_payload = {
            files: imgArr,
          };

          // console.log("payload", payload);

          if (data.pi_image?.length > 0) {
            const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
            const idToken = localStorage.getItem("id");
            
            axios.post(url, img_payload, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
              }
            }).then(
              (res) => {
                // console.log("res", res);
                for(let x = 0; x < res.data.data?.body?.length; x++){
                  const url2 = res.data.data?.body?.[x]?.url;

                  axios.put(url2, data.pi_image?.[0], {
                    headers: {
                      'Content-Type': data.pi_image?.[0]?.type
                    }
                  }).then(
                    (ress) => {
                      toast.success("Material inward created successfully!", {
                        autoClose: 2000,
                      });
                      setTimeout(() => {
                        navigate("/pcms/purchaseInward");
                        reset();
                      }, [2300]);
                      setLockBtn(false);
                    },
                    (errr) => {
                      console.log("errr", errr);
                      if (errr.response.status == 413) {
                        toast.error("Image upload failed !", {
                          autoClose: 3000,
                        });
                      }
                    }
                  );
                }
              },
              (err) => {
                console.log("err", err);
              }
            );
          } else {
            toast.success("Material inward created successfully!", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/purchaseInward");
              reset();
            }, [2300]);
            setLockBtn(false);
          }
        } 
      },
      (err) => {
        if (err.response.status === 401) {
          toast.error(err.response.statusText, {
            autoClose: 3000,
          });
        }
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setRMI_Data([]);
        setLockBtn(false);
      }
    );
  };

  const handleRemove = (index) => {
    remove(index);
  };

  function form_data(index) {
    const invoiceNo = getValues("invoice_no");
    const invoiceDate = getValues("invoice_date");
    const received_qty = getValues(`items[${index}].received_qty`);
    const product = getValues(`items[${index}].product`);
    const amount = getValues(`items[${index}].amount`);
    const material_code = getValues(`items[${index}].material_code`);
    const supplier = getValues(`supplier`);

    if (
      invoiceNo !== undefined &&
      invoiceNo !== null &&
      invoiceDate !== undefined &&
      invoiceDate !== null &&
      received_qty !== undefined &&
      received_qty !== null &&
      product !== undefined &&
      product !== null
    ) {
      const data = {
        invoiceNo: invoiceNo,
        invoiceDate: invoiceDate,
        received_qty: received_qty,
        product: product,
        amount: amount,
        material_code: material_code,
        supplier: supplier,
      };
      localStorage.setItem("form-data", JSON.stringify(data));
      setRMIModal(true);
      clearErrors("invoice_no");
      clearErrors("invoice_date");
    } else {
      // Set error message for both invoice no and invoice date
      setError("invoice_no", {
        type: "manual",
        message: "Please enter invoice no!",
      });
      setError("invoice_date", {
        type: "manual",
        message: "Please enter invoice date!",
      });
    }
  }

  function fetchMaterialCode() {
    get_material_code().then(
      (res) => {
        setMat_Code(res.data.data);
      },
      (err) => {
        setMat_Code();
      }
    );
  }

  return (
    <React.Fragment>
      <CreateRMI
        rmiModal={rmiModal}
        setRMIModal={setRMIModal}
        currIndex={currIndex}
        rmi_data={rmi_data}
        setRMI_Data={setRMI_Data}
      />
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body p-4">
              <div className="row ">
                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_invoice_to
                        ? constLabel.lbl_invoice_to
                        : "Invoice To"}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name={`invoiceTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={internalCompList.data}
                          isLoading={internalCompList.loading}
                          placeholder="Select invoice to"
                          // onChange={onChange}
                          onChange={(e) => {
                            onChange(e);
                            // console.log("e", e);
                            setLoc_Name({
                              ...loc_name,
                              data: [],
                              loading: true,
                            });
                            if (e?.length > 0) {
                              setLoc_Name({
                                ...loc_name,
                                data: e[0]?.location_list,
                                loading: false,
                              });
                              setValue("dispatchTo", e);
                            } else {
                              setLoc_Name({
                                ...loc_name,
                                data: [],
                                loading: false,
                              });
                              setValue("location", []);
                            }
                          }}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.invoiceTo ? true : false}
                        />
                      )}
                    />
                    {!internalCompList.loading && !errors.invoiceTo && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.invoiceTo && (
                      <span className="text-danger err-msg">
                        {errors.invoiceTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_dispatch_to
                        ? constLabel.lbl_dispatch_to
                        : "Dispatch To"}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name={`dispatchTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={internalCompList.data}
                          isLoading={internalCompList.loading}
                          placeholder="Select dispatch to"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.dispatchTo ? true : false}
                        />
                      )}
                    />
                    {!internalCompList.loading && !errors.dispatchTo && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.dispatchTo && (
                      <span className="text-danger err-msg">
                        {errors.dispatchTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_supplier_name
                        ? constLabel.lbl_supplier_name
                        : "Supplier Name"}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name={`supplier`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="partyname"
                          multiple={false}
                          options={supplierList.data}
                          isLoading={supplierList.loading}
                          placeholder="Select supplier / customer"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.supplier ? true : false}
                        />
                      )}
                    />
                    {!supplierList.loading && !errors.supplier && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.supplier && (
                      <span className="text-danger err-msg">
                        {errors.supplier.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_voucher_no
                        ? constLabel.lbl_voucher_no
                        : "Voucher No"}
                      {/* <span className="text-danger"> *</span> */}
                    </label>
                    <input
                      className="form-control mt-2"
                      type="text"
                      id="voucher_no"
                      name="voucher_no"
                      defaultValue={"0"}
                      placeholder="Enter voucher no / challan no"
                      {...register("voucher_no")}
                    />
                    {/* <span className="text-danger err-msg">
                        {errors.tally_voucher_no?.message}
                        </span> */}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_location_name
                        ? constLabel.lbl_location_name
                        : "Location Name"}
                      <span className="text-danger"> *</span>
                    </label>

                    <Controller
                      name="location"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="locationname"
                          multiple={false}
                          options={
                            loc_name.data !== undefined ? loc_name.data : []
                          }
                          isLoading={loc_name.loading}
                          placeholder="Select location "
                          onChange={(selected) => {
                            onChange(selected);

                            if (selected.length > 0) {
                              fetchStore(selected[0]?.uuid);
                            } else {
                              setValue("location", [], {
                                shouldValidate: true,
                              });
                            }
                          }}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.location ? true : false}
                        />
                      )}
                    />
                    {!loc_name.loading && !errors.location && (
                      <span className="arrow-icon"></span>
                    )}
                    <span className="text-danger err-msg">
                      {errors.location?.message}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_tally_voucher_no
                        ? constLabel.lbl_tally_voucher_no
                        : "Tally Voucher No"}
                      {/* <span className="text-danger"> *</span> */}
                    </label>
                    <input
                      className={
                        errors.tally_voucher_no
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="tally_voucher_no"
                      name="tally_voucher_no"
                      defaultValue={"0"}
                      placeholder="Enter tally voucher no / requisition no"
                      {...register("tally_voucher_no")}
                    />
                    {/* <span className="text-danger err-msg">
                        {errors.tally_voucher_no?.message}
                        </span> */}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_tally_voucher_date
                        ? constLabel.lbl_tally_voucher_date
                        : "Tally Voucher Date"}
                      {/* <span className="text-danger"> *</span> */}
                    </label>
                    <Controller
                      name="tally_voucher_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.tally_voucher_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          {...field}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={field.value || null}
                          defaultValue={new Date()}
                          placeholderText="Select tally voucher date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {/* {errors.tally_voucher_date && (
                        <span className="text-danger err-msg">
                            {errors.tally_voucher_date.message}
                        </span>
                        )} */}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_invoice_no
                        ? constLabel.lbl_invoice_no
                        : "Invoice No"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={
                        errors.invoice_no
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="invoice_no"
                      name="invoice_no"
                      placeholder="Enter invoice no"
                      {...register("invoice_no")}
                    />
                    <span className="text-danger err-msg">
                      {errors.invoice_no?.message}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_invoice_date
                        ? constLabel.lbl_invoice_date
                        : "Invoice Date"}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name="invoice_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.invoice_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          {...field}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={field.value || null}
                          placeholderText="Select invoice date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.invoice_date && (
                      <span className="text-danger err-msg">
                        {errors.invoice_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_upload_image
                        ? constLabel.lbl_upload_image
                        : "Upload Image"}
                    </label>
                    <input
                      className={
                        errors.pi_image
                          ? "form-control is-invalid mt-2"
                          : "form-control mt-2"
                      }
                      type="file"
                      name="pi_image"
                      {...register("pi_image")}
                      accept="image/*"
                    />
                    {errors.pi_image && (
                      <span className="text-danger err-msg">
                        {errors.pi_image.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div
            className="row "
            style={{ paddingTop: "10px", paddingBottom: "25px" }}
          >
            <div className="col-12 mt-3 ">
              <div className="card">
                <div className="card-body">
                  <div className="my-3 d-flex justify-content-between">
                    <i className={`${(localData?.industry_type?.toUpperCase() !== name_const.MANUFACTURING.toUpperCase() ? "invisible" : null)}`}>
                      {" "}
                      <span className="text-danger roboto-bold">Note: </span>
                      <FontAwesomeIcon
                        style={{
                          color: "#c7c7c7",
                        }}
                        icon={faCircle}
                        size="2xs"
                        className="mx-2"
                      />
                      {"RMI inward link will be visible only for BAR, FORGING, CASTING"}
                    </i>
                    <div>
                      <button
                        className="btn btn-sm button-primary px-2 me-2"
                        onClick={() =>
                          append({
                            product: [],
                            rate: 0,
                            received_qty: 0,
                            amount: 0,
                            remark: "",
                          })
                        }
                      >
                        {constLabel?.lbl_add ? constLabel.lbl_add : "Add"}
                      </button>
                      <button
                        className="btn btn-sm btn-yellow px-2"
                        onClick={() => reset()}
                      >
                        {constLabel?.lbl_reset ? constLabel.lbl_reset : "Reset"}
                      </button>
                    </div>
                  </div>
                  <div>
                    {fields.map(({ id }, index) => (
                      <div key={index} className="card rounded shadow-sm mb-4">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_product_name
                                  ? constLabel.lbl_product_name
                                  : "Product Name"}{" "}
                                  <span className="text-danger">*</span>
                              </label>

                              <Controller
                                name={`items[${index}].product`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="productNameWithPart"
                                    size="sm"
                                    multiple={false}
                                    options={prod_List.data}
                                    isLoading={prod_List.loading}
                                    placeholder="Select product"
                                    onChange={(selected) => {
                                      onChange(selected);
                                      console.log("selected", selected);
                                      if (selected.length > 0) {
                                        setValue(
                                          `items[${index}].unit`,
                                          selected[0]?.unit_id?.uom,
                                          {
                                            shouldValidate: true,
                                          }
                                        );
                                        setValue(
                                          `items[${index}].store`,
                                          selected[0]?.store_id !== null
                                            ? [selected[0]?.store_id]
                                            : def_store && def_store,
                                          {
                                            shouldValidate: true,
                                          }
                                        );

                                        selected?.[0]?.product_type_id?.productType == "Raw material" &&
                                        selected?.[0]?.material_type_id?.material_type &&
                                        ["BAR", "CASTING", "FORGING"].includes(
                                          selected?.[0]?.material_type_id?.material_type
                                        )
                                          ? setValue(
                                            `items[${index}].material_code`,
                                            index == 0
                                              ? mat_code
                                              : incrementMaterialCode(
                                                  mat_code,
                                                  index
                                                )
                                          )
                                          : setValue(
                                            `items[${index}].material_code`,
                                            ""
                                          )
                                        
                                      } else {
                                        setValue(`items[${index}].unit`, "");
                                        setValue(`items[${index}].store`, []);
                                        setValue(
                                          `items[${index}].material_code`,
                                          ""
                                        );
                                      }
                                    }}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.product
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {watch(`items[${index}].product`)?.[0]
                                ?.product_type_id?.productType ===
                                "Raw material" &&
                                ["BAR", "CASTING", "FORGING"].includes(watch(`items[${index}].product`)[0]
                                ?.material_type_id?.material_type
                                ) && rmi_config?.isBarConfigure ? (
                                <a
                                  className="text-primary cursor_pointer mt-2"
                                  onClick={() => {
                                    setCurrIndex(index);
                                    form_data(index);
                                  }}
                                  id="rmi_inward_link"
                                >
                                  RMI Inward
                                </a>
                              ) : null}
                              {errors.items?.[index]?.product && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.product.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_batch_code
                                  ? constLabel.lbl_batch_code
                                  : "Batch Code"}
                              </label>

                              <input
                                className={
                                  errors.items?.[index]?.material_code
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].material_code`}
                                placeholder="Enter batch code"
                                {...register(`items[${index}].material_code`)}
                                disabled={
                                  watch(`items[${index}].product`)?.[0]
                                    ?.product_type_id?.productType ===
                                    "Raw material" && 
                                  ["BAR", "CASTING", "FORGING"].includes(
                                    watch(`items[${index}].product`)[0]
                                    ?.material_type_id?.material_type)
                                    ? true
                                    : false
                                }
                              />
                              {errors.items?.[index]?.material_code && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.material_code.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-2">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_unit
                                  ? constLabel.lbl_unit
                                  : "Unit"}
                              </label>

                              <input
                                className={"form-control form-control-sm"}
                                type="text"
                                disabled
                                name={`items[${index}].unit`}
                                {...register(`items[${index}].unit`)}
                              />
                            </div>

                            <div className="col-12 col-md-2">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_received_qty
                                  ? constLabel.lbl_received_qty
                                  : "Received Qty"}{" "}
                                  <span className="text-danger">*</span>
                              </label>

                              <input
                                className={
                                  errors.items?.[index]?.received_qty
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                placeholder="Enter received qty"
                                name={`items[${index}].received_qty`}
                                {...register(`items[${index}].received_qty`)}
                              />

                              {errors.items?.[index]?.received_qty && (
                                <span className="text-danger err-msg">
                                    {errors.items?.[index]?.received_qty.message}
                                  </span>
                              )}
                            </div>

                            <div className="col-12 col-md-2">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_amount
                                  ? constLabel.lbl_amount
                                  : "Amount"}{" "}
                                  <span className="text-danger">*</span>
                              </label>

                              <input
                                className={
                                  errors.items?.[index]?.amount
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                placeholder="Enter amount"
                                name={`items[${index}].amount`}
                                {...register(`items[${index}].amount`)}
                              />

                              {errors.items?.[index]?.amount && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.amount.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-3 mt-2">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_store
                                  ? constLabel.lbl_store
                                  : "Select Store"}

                                <Tooltip id={"open-tooltip3"} place="top" />
                                <img
                                  src={info}
                                  alt="info"
                                  className="cursor_pointer"
                                  data-tooltip-id={"open-tooltip3"}
                                  data-tooltip-content={
                                    "Select location to enable store"
                                  }
                                  height={18}
                                  width={18}
                                />{" "}
                                  <span className="text-danger">*</span>
                              </label>

                              <Controller
                                name={`items[${index}].store`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    size="sm"
                                    multiple={false}
                                    options={storeList.data}
                                    loading={storeList.loading}
                                    placeholder="Select store"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    disabled
                                    isInvalid={
                                      errors.items?.[index]?.store
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.store && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.store.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-3 mt-2">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_remarks
                                  ? constLabel.lbl_remarks
                                  : "Remarks"}
                              </label>

                              <input
                                className={
                                  errors.items?.[index]?.remark
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].remark`}
                                placeholder="Enter remark"
                                {...register(`items[${index}].remark`)}
                              />
                            </div>

                            <div className="col-12 col-md-2 mt-4">
                              <div className="d-flex justify-content-start">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      {constLabel?.lbl_delete_item
                                        ? constLabel.lbl_delete_item
                                        : "Delete Item"}
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    className="btn btn-sm button-danger px-3 mt-2"
                                    onClick={() => {
                                      handleRemove(index);
                                    }}
                                  >
                                    Delete
                                    {/* <Trash2 color="#FFFFFF" className="ms-2" size={16} /> */}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn btn-grey px-4 ms-0 ms-md-2"
            onClick={handleSubmit(onSubmit)}
            disabled={lockBtn}
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
            {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PIWithoutPO;
