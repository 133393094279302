import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { Pagination, Spinner } from "react-bootstrap";
import { faBan, faCog, faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  getLocation,
  getProdList,
  get_product_light,
  get_stock_availability_report,
  get_stock_by_product,
  get_store,
} from "../../api";
import { ToastContainer, toast } from "react-toastify";
import {
  calMaxPage,
  calMaxPage_new,
  convertDateYYMMDD,
  formatDate5,
  formatDateString5,
  getLabels,
  setAccessPrivilage,
  setTimeToZero,
} from "../../common/Functions/CommonFunctions";
import { useSelector } from "react-redux";
import { Stock_Avail_Col_Name } from "../../common/Labels/labelConstant";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import List from "../../assets/images/icons/project_menu/checklist.png";
import { Tooltip } from "react-tooltip";
import AccessDenied from "../Common/AccessDenied";
import * as name_const from "../../common/Constants/constants";

const schema = yup
  .object()
  .shape({
    location: yup
      .array()
      .min(1, "Please select location")
      .required("Please select location"),
  })
  .required();

function StockAvailability() {
  let navigate = useNavigate();
  let location = useLocation();
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [lockBtn, setLockBtn] = useState(false);
  const [showzero, setShowzero] = useState(false);

  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [locData, setLocData] = useState({ data: [], loading: false });
  const [prodList, setProdList] = useState({ data: [], loading: false });
  const [storeList, setStoreList] = useState({ data: [], loading: false });
  const [loc_store, setLoc_Store] = useState({ data: [], loading: false });

  const [sel_store, setSel_Store] = useState([]);
  const [prod, setProd] = useState({searchVal: ""})

  const [filterConfig, setFilterConfig] = useState({
    product_uuid: [],
    location_uuid: [],
    store_uuid: [],
  });

  const [showLabel, setShowLabel] = useState(Stock_Avail_Col_Name);
  const [constLabel, setConstLabel] = useState({});
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const { control, formState, setError, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const selLoc = watch("location");
  const selProd = watch("product");
  const selStore = watch("store");

  // useEffect(() => {
  //   fetchProdList();
  //   fetchLocation();
  //   fetchStores();
  // }, []);

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, name_const.STOCK_AVAILABILITY));
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([fetchLocation()]); //fetchStores()
      setInitialDataLoaded(true);
      // console.log("location", location);
      // if(location.pathname = "/pcms/report/stock-availability"){
      //   if(location.state?.store_data?.length > 0){
      //     setFilterConfig({...filterConfig, location_uuid: location.state?.location_data ?? [], product_uuid: location.state?.product_data !== undefined ? location.state?.product_data : [{uuid: location.state?.product_id, productId: location.state?.product, productName: location.state?.product_name }] ?? [], store_uuid: location.state?.store_data ?? [] })
      //   } else {
      //     setFilterConfig({...filterConfig, location_uuid: location.state?.location_data ?? [], product_uuid: location.state?.product_data !== undefined ? location.state?.product_data : [{uuid: location.state?.product_id, productId: location.state?.product, productName: location.state?.product_name }] ?? [] })
      //   }
      // }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (!initialDataLoaded) return;
    setConstLabel(getLabels(name_const.REPORT, name_const.STOCK_AVAILABILITY));
    setDataList({ data: [], loading: false });
    if (
      filterConfig.location_uuid.length > 0 ||
      (filterConfig.product_uuid.length > 0 &&
        filterConfig.location_uuid.length > 0) ||
      (filterConfig.product_uuid.length > 0 &&
        filterConfig.location_uuid.length > 0 &&
        filterConfig.store_uuid.length > 0)
    ) {
      const debounceTimeout = setTimeout(() => {
        setPage(1);
        fetchAvailableStock(page, entriesPerPage);
      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

      return () => {
        clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
      };
    }
  }, [filterConfig, initialDataLoaded, showzero, startDate, endDate]);

  useEffect(() => {
      const debounceTimeout = setTimeout(() => {
        fetchProdList();
      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
  
      return () => {
        clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
      };
    }, [prod]);

  function fetchProdList() {
    setProdList({ ...prodList, data: [], loading: true });
    const payload = {};
    if (prod.searchVal !== "" && prod.searchVal.length >= 4) {
          payload.searchVal = prod.searchVal.trim(); // Search by product name
          get_product_light(payload).then(
            (res) => {
              let arr = res.data.data.filter(
                (item) => item.productName !== null || item.productName !== undefined
              );
              setProdList({ ...prodList, data: arr, loading: false });
            },
            (err) => {
              setProdList({ ...prodList, data: [], loading: false });
            }
          );
        } else {
          setProdList({ ...prodList, data: [], loading: false });
        }
  }

  // async function fetchStores() {
  //   setStoreList({ ...storeList, data: [], loading: true });

  //   await get_store().then(
  //     (res) => {
  //       setStoreList({ ...storeList, data: res.data.data, loading: false });
  //     },
  //     (err) => {
  //       setStoreList({ ...storeList, data: [], loading: false });
  //     }
  //   );
  // }

  async function fetchStores(location_uuid) {
    setLoc_Store({ ...loc_store, data: [], loading: true });

    await get_store({ location_id: location_uuid }).then(
      (res) => {
        if (res.data.data.length == 0) {
          toast.error("Store not found for selectd location !");
        }
        const result = res.data.data?.filter((itm) => itm.name?.toLowerCase() === "purchase");
        if(result){
          setValue("store", result, {shouldValidate: true});
          setFilterConfig((prev) => ({...prev, store_uuid: result}));
        }
        setLoc_Store({ ...loc_store, data: res.data.data, loading: false });
      },
      (err) => {
        setLoc_Store({ ...loc_store, data: [], loading: false });
      }
    );
  }

  async function fetchLocation() {
    setLocData({ loading: true, data: [] });

    await getLocation().then(
      async (res) => {
        // console.log("res.data.data", res.data.data)
        const result = res.data.data.filter(
          (item) => item.locationname !== "" && item.locationname !== null && item.locationname !== undefined);

        // console.log("result", result)
        setLocData({
          loading: false,
          data: result,
        });

        //  if(result.length>0){
        //   setFilterConfig({
        //     ...filterConfig,
        //     location_uuid: [ress[0]]
        //   });

        //  }

        // if(result.length>0){
        // await  get_store().then(
        //     (res) => {
        //       const initialStores = res.data.data.filter(
        //         item => item.location_id == result[0].uuid
        //       );
        //       setLoc_Store({ data: initialStores, loading: false });
        //     },
        //     (err) => {
        //       setLoc_Store({ data: [], loading: false });
        //     }
        //   );
        // }
      },
      (err) => {
        //
        setLocData({ loading: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchAvailableStock(pg, epp) {
    setDataList({ data: [], loading: true });
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.product_uuid.length > 0) {
      payload.productid_id = filterConfig.product_uuid?.[0]?.uuid;
    } else {
      delete payload.productid_id;
    }

    if (filterConfig.location_uuid.length > 0) {
      payload.location_id = filterConfig.location_uuid?.[0]?.uuid;
    } else {
      delete payload.location_id;
    }

    if (filterConfig.store_uuid.length > 0) {
      payload.store_id = filterConfig.store_uuid?.[0]?.uuid;
    } else {
      delete payload.store_id;
    }

    payload.showZero = showzero;
    get_stock_by_product(payload).then(
      (res) => {
        // console.log("res", res);

        setDataList({
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        // setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));
        // console.log("calMaxPage_new(res.data.total,entriesPerPage",calMaxPage_new(res.data.total,epp))
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        if (err.response.data.message !== "location_id is required") {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }

        setDataList({ data: [], loading: false });
      }
    );
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function clearFilter() {
    let obj = {
      product_uuid: [],
      location_uuid: [],
      store_uuid: [],
    };
    setFilterConfig({ ...obj });
    setStartDate(getFinancialYearStartDate);
    setEndDate(new Date());
    setSel_Store([]);
  }

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    // console.log("endDate", endDate);
    return endDate;
  }

  function getFinancialYearStartDate() {
    const today = new Date();
    const year = today.getMonth() >= 3 ? today.getFullYear() - 1 : today.getFullYear() - 2;
    return new Date(year, 3, 1);
  }

  // function getFinancialYearStartDate() {
  //   const today = new Date();
  //   const year =
  //     today.getMonth() >= 3 ? today.getFullYear() : today.getFullYear() - 1;
  //   const financialYearStartDate = new Date(year, 3, 1); // April is month 3 in JavaScript Date
  //   return financialYearStartDate;
  // }

  const handleFromDateChange = (date) => {
    if (endDate && setTimeToZero(date) > setTimeToZero(endDate)) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && setTimeToZero(date) < setTimeToZero(startDate)) {
      return;
    }
    setEndDate(date);
  };

  async function goToDownload() {
    setLockBtn(true);
    // console.log("inside download excel", filterConfig);
    const payload = {
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.product_uuid.length > 0) {
      payload.productid_id = filterConfig.product_uuid?.[0]?.uuid;
    } else {
      delete payload.productid_id;
    }

    if (filterConfig.location_uuid.length > 0) {
      payload.location_id = filterConfig.location_uuid?.[0]?.uuid;
    } else {
      delete payload.location_id;
    }

    if (filterConfig.store_uuid.length > 0) {
      payload.store_id = filterConfig.store_uuid?.[0]?.uuid;
    } else {
      delete payload.store_id;
    }

    // payload.showZero=showzero

    // await getProductionData(payload).then(
    await get_stock_availability_report(payload).then(
      (res) => {
        // console.log("res", res);
        if (res && res.data.isBase64Encoded == true) {
          // excel_download(res.data.data);
          base64ToExcel(res.data);
        } else {
          toast.success(res.data.message, {
            autoClose: 2000,
          });
          setLockBtn(false);
        }
      },
      (err) => {
        // console.log("err", err);
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
        setLockBtn(false);
      }
    );
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `StockReport${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockBtn(false);
  }

  function callStockLedger(val) {
    if (sel_store && sel_store.length > 0) {
      let newVal = {
        ...val,
        location_data: selLoc,
        product_data: selProd,
        store_data: selStore,
        // store_id: sel_store?.[0]?.uuid,
        startDate: startDate,
        endDate: endDate,
      };
      navigate(`/pcms/report/stock-ledger`, {
        state: newVal,
      });
    } else {
      let newVal = { ...val, location_data: selLoc, product_data: selProd, store_data: selStore, startDate: startDate, endDate: endDate };
      navigate(`/pcms/report/stock-ledger`, {
        state: newVal,
      });
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {!privilage.read ?
        <AccessDenied />
        :

        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="bold">
                {constLabel?.lbl_stock_availability
                  ? constLabel.lbl_stock_availability
                  : "Stock Availability"}
              </h1>
              <div>
                {privilage.report == true ? (
                  <button
                    className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                    disabled={lockBtn}
                    onClick={() => goToDownload()}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#fff" }}
                      icon={faDownload}
                      size="sm"
                    />{" "}
                    {constLabel?.lbl_stock_report
                      ? constLabel.lbl_stock_report
                      : "Stock Report"}{" "}
                    {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                  </button>
                ) : null}
              </div>
            </div>

            <div className="card border-0 mt-2 mb-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group mt-md-0 mt-2">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_from_date
                          ? constLabel.lbl_from_date
                          : "From Date"}
                      </label>
                      <DatePicker
                        id="fromDate"
                        className="form-control"
                        selected={startDate}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                        placeholderText="From Date"
                        // onChange={(date) => setStartDate(date)}
                        onChange={(date) => handleFromDateChange(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group mt-md-0 mt-2">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_to_date
                          ? constLabel.lbl_to_date
                          : "To Date"}
                      </label>
                      <DatePicker
                        id="toDate"
                        className="form-control"
                        showYearDropdown
                        scrollableMonthYearDropdown
                        selected={endDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="To Date"
                        // onChange={(date) => setEndDate(date)}
                        onChange={(date) => handleToDateChange(date)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group typeahead-container mt-md-0 mt-2">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_product
                          ? constLabel.lbl_product
                          : "Product"}
                      </label>
                      <Controller
                        name={`product`}
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="productName"
                            multiple={false}
                            options={
                              prodList.data !== undefined ? prodList.data : []
                            }
                            isLoading={prodList.loading}
                            placeholder="Select product"
                            selected={filterConfig.product_uuid}
                            onInputChange={(text) =>
                              setProd({ ...prod, searchVal: text })
                            }
                            onChange={(val) => {
                              onChange(val);
                              setFilterConfig({
                                ...filterConfig,
                                product_uuid: val.length > 0 ? val : [], // Store single value
                              });
                            }}
                          />
                        )}
                      />
                      {!prodList.loading && <span className="arrow-icon"></span>}
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group typeahead-container mt-md-0 mt-2">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_location
                          ? constLabel.lbl_location
                          : "Location"}

                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        name={`location`}
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="locationname"
                            multiple={false}
                            options={
                              locData.data !== undefined ? locData.data : []
                            }
                            isLoading={locData.loading}
                            placeholder="Select location"
                            selected={filterConfig.location_uuid}
                            onChange={(val) => {
                              onChange(val);
                              if (val.length > 0) {
                                setFilterConfig({
                                  ...filterConfig,
                                  location_uuid: val,
                                });

                                fetchStores(val[0].uuid);

                                // let arr = storeList.data.filter(
                                //   (item) => item.location_id == val[0].uuid
                                // );
                                // setLoc_Store({ data: arr, loading: false });
                              } else {
                                setFilterConfig({
                                  ...filterConfig,
                                  location_uuid: [],
                                });
                                setLoc_Store({ data: [], loading: false });
                              }
                            }}
                            isInvalid={errors.location ? true : false}
                          />
                        )}
                      />
                      {!locData.loading && !errors.location && <span className="arrow-icon"></span>}
                      <span className="text-danger err-msg">
                        {errors.location?.message}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-3">
                    <div className="form-group typeahead-container mt-md-0 mt-2">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_store ? constLabel.lbl_store : "Store"}
                      </label>
                      <Controller
                        name={`store`}
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            options={
                              loc_store.data !== undefined ? loc_store.data : []
                            }
                            isLoading={loc_store.loading}
                            placeholder="Select store"
                            selected={value}
                            onChange={(val) => {
                              onChange(val);
                              if (val.length > 0) {
                                setSel_Store(val);
                                setFilterConfig({
                                  ...filterConfig,
                                  store_uuid: val,
                                });
                              } else {
                                setSel_Store([]);
                                setFilterConfig({
                                  ...filterConfig,
                                  store_uuid: [],
                                });
                              }
                            }}
                          />
                        )}
                      />
                      {!loc_store.loading && <span className="arrow-icon"></span>}
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-3 mt-5">
                  
                  
                  <div className="form-check form-switch" style={{paddingLeft: "3rem"}}>
                      <label
                        className="form-check-label lbl-style roboto-bold"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        {"View Zero Qty Product"}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={showzero}
                        onChange={() => {setShowzero(!showzero);}}
                      />
                  </div>
                
                  </div> */}
                  <div className="col-12 col-md-3 mt-5">
                    <div>
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold invisible"
                      >
                        {constLabel?.lbl_store ? constLabel.lbl_store : "Store"}
                      </label>

                      <button
                        onClick={() => clearFilter()}
                        className="btn btn-sm btn-yellow "
                      >
                        <FontAwesomeIcon
                          style={{ color: "#344454" }}
                          icon={faBan}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_clear_filter
                          ? constLabel.lbl_clear_filter
                          : "Clear Filter"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card border-0">
              <div className="card-body">
                <div className="d-flex justify-content-end align-items-center">
                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                          <li key={ind}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                onChange={() => handleClick(ind)}
                                value=""
                                checked={val.flag}
                              />
                              <label
                                className=""
                                style={{ fontSize: "13px" }}
                                htmlFor="flexCheckDefault"
                              >
                                {val.label}
                              </label>
                            </div>
                          </li>
                        ))
                        : null}
                    </ul>
                  </div>
                </div>

                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table mt-2 table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "Part No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_part_no
                                    ? constLabel.lbl_part_no
                                    : "Part No"}
                                </th>
                              ) : v.label === "Product" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_product
                                    ? constLabel.lbl_product
                                    : "Product"}
                                </th>
                              ) : v.label === "Quantity" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_quantity
                                    ? constLabel.lbl_quantity
                                    : "Quantity"}{" "}
                                  {/* {sel_store && sel_store.length > 0 ? (
                                    <em>(Store)</em>
                                  ) : (
                                    <em>(Location)</em>
                                  )}{" "} */}
                                </th>
                              ) : v.label === "Inventory Value" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_inventory_value
                                    ? constLabel.lbl_inventory_value
                                    : "Inventory Value"}{" "}(<span>&#8377;</span>)
                                </th>
                              ) : v.label === "Last Update Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_last_update_date
                                    ? constLabel.lbl_last_update_date
                                    : "Last Update Date"}
                                </th>
                              ) : null
                            )}
                            {/* <th scope='col'>{"Action"}</th> */}
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Part No" && item.flag ? (
                                  <td key={idx}>
                                    {val.partNo &&
                                      val.partNo !== "" &&
                                      val.partNo !== null
                                      ? val.partNo
                                      : val.product}
                                  </td>
                                ) : item.label === "Product" && item.flag ? (
                                  <td key={idx}>
                                    {val.product_name !== "" &&
                                      val.product_name !== null ?
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          callStockLedger(val);
                                        }}
                                      >{val.product_name}
                                        {/* {val.product_name !== "" &&
                                    val.product_name !== null
                                      ? val.product_name
                                      : "NA"} */}
                                      </a> : "NA"}
                                  </td>
                                ) : item.label === "Quantity" && item.flag ? (
                                  <td key={idx}>
                                    {sel_store && sel_store.length > 0
                                      ? val.quantity !== "" &&
                                        val.quantity !== null
                                        ? val.quantity < 0
                                          ? `(${Math.abs(val.quantity)}) ${val.uom && val.uom !== null
                                            ? val.uom
                                            : ""
                                          }`
                                          : `${val.quantity} ${val.uom && val.uom !== null
                                            ? val.uom
                                            : ""
                                          }`
                                        : "NA"
                                      : val.quantity !== "" &&
                                        val.quantity !== null
                                        ? val.quantity < 0
                                          ? `(${Math.abs(val.quantity)}) ${val.uom && val.uom !== null
                                            ? val.uom
                                            : ""
                                          }`
                                          : `${val.quantity} ${val.uom && val.uom !== null
                                            ? val.uom
                                            : ""
                                          }`
                                        : "NA"}
                                  </td>
                                ) : item.label === "Inventory Value" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.final_price !== "" && val.final_price !== null
                                      ? val.final_price < 0
                                        ? <span>&#8377;{" "}{`(${Math.abs(val.final_price).toFixed(2)})`}</span>
                                        : <span>&#8377;{" "}{`${(val.final_price).toFixed(2)}`}</span>
                                      : 0}
                                  </td>
                                ) : item.label === "Last Update Date" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.modified !== "" && val.modified !== null
                                      ? formatDate5(val.modified)
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              {/* <td>
                              {privilage.read &&
                                <>
                                  <Tooltip id={"pdf-tooltip" + ind} place="top" />
                                  <div
                                    data-tooltip-id={"pdf-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_stock_ledger
                                        ? constLabel.lbl_stock_ledger
                                        : "Stock Ledger"
                                    }
                                    className="downloadIcon m-1 cursor_pointer"
                                    onClick={() => {
                                      navigate(
                                        `/pcms/report/stock-ledger`,
                                        { state: val }
                                      );
                                    }}
                                  >
                                    <FileText color="#FFFFFF" size={18} />
                                  </div>
                                </>
                                }
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchAvailableStock(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          dataList.totalRecords
                        )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchAvailableStock(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchAvailableStock(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {selLoc && selLoc.length > 0
                      ? "Available stock not found !"
                      : "Please select product and location !"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      }
    </React.Fragment>
  );
}

export default StockAvailability;