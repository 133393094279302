import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  faBan,
  faPlus,
  faCircle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Pagination, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Eye, Trash2, Edit2, Edit, FileText, Image, Scissors } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getSalesOrder,
  deleteSalesOrder,
  getCustomer,
  getSOStatus,
  getProdList,
  get_state,
  get_product_light,
  get_prod_report_fashion,
} from "../../api";
import { Tooltip } from "react-tooltip";
import Multiselect from "multiselect-react-dropdown";
import {
  calMaxPage,
  convertDate,
  convertDateYYMMDD,
  getFinancialYearStartDate,
  getLabels,
  setAccessPrivilage,
  setTimeToZero,
} from "../../common/Functions/CommonFunctions";
import { Sales_Order_Col_Name } from "../../common/Labels/labelConstant";
import List from "../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../assets/images/icons/project_menu/collapse.png";
import AccessDenied from "../Common/AccessDenied";
import {
  APPROVED,
  CANCELLED,
  COMPLETED,
  DELIVERED,
  IN_PROGRESS, BOMCREATED,
  OPEN, FASHION, APPAREL
} from "../../common/Constants/constants";
import { resetState } from "../../slices/production_planning/production_plan_slice";
import { useSelector, useDispatch } from "react-redux";
import { reset_operations } from "../../slices/production_planning/route_operation_slice";
import { reset_state } from "../../slices/production_planning/employee_availability_slice";
import { reset_productSlice } from "../../slices/production_planning/production_plan_slice2";
import { reset_routes } from "../../slices/production_planning/routeSlice";
import { reset_production_plan } from "../../slices/production_planning/production_plan_by_id_slice";
import Dropdown from 'react-bootstrap/Dropdown';
import FilesUpload from "./FilesUpload";

function SalesOrder() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [showLabel, setShowLabel] = useState(Sales_Order_Col_Name);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [filterConfig, setFilterConfig] = useState({
    customer_name: [], //array
    so_status: [], //string
    startPrice: "",
    endPrice: "",
    gst_number: [], //array
    productName: [], //array
    stateName: [],
  });

  const [priceRange, setPriceRange] = useState({ from: 1, to: 1000 });
  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [gst_list, setGST_list] = useState({ data: [], loading: false });
  const [state_list, setState_list] = useState({ data: [], loading: false });
  const [soStatusList, setSOStatusList] = useState({
    data: [],
    loading: false,
  });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [constLabel, setConstLabel] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [lockBtn, setLockBtn] = useState(false);

  const [visible, setVisible] = useState(false);
  const [so_data, set_so_data] = useState({});
  // State to track selected order IDs across pages
  const [selectedIds, setSelectedIds] = useState(new Set());


  // State to track all checked items separately
  const [checkedItems, setCheckedItems] = useState([]);
  const showDropdown = JSON.parse(localStorage.getItem("splitView"));

  let navigate = useNavigate();

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false
  });

  const localData = JSON.parse(localStorage.getItem("localData"));

  const handleSliderChange = (event, sliderName) => {
    const value = parseInt(event.target.value, 10);
    let obj = {};
    // Reset error message
    setErrorMessage("");

    if (sliderName == "from") {
      obj.startPrice = value;
      obj.endPrice = priceRange.to;

      // Check if startPrice is greater than or equal to endPrice, then set error message
      if (value >= priceRange.to) {
        setErrorMessage("From price must be less than To price");
        return;
      }
    } else {
      obj.endPrice = value;
      obj.startPrice = priceRange.from;

      // Check if endPrice is less than or equal to startPrice, then set error message
      if (value <= priceRange.from) {
        setErrorMessage("To price must be greater than From price");
        return;
      }
    }
    setPriceRange({ ...priceRange, [sliderName]: value });
    setFilterConfig({ ...filterConfig, ...obj });
  };

  useEffect(() => {
    setConstLabel(getLabels("Sales", "Sales Order"));
    const debounceTimeout = setTimeout(() => {
      fetchSOList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  useEffect(() => {
    // console.log(loginData)
    setPrivilage(setAccessPrivilage(loginData, "Sales Order"));

    dispatch(resetState());
    dispatch(reset_operations());
    dispatch(reset_state());
    dispatch(reset_productSlice());
    dispatch(reset_routes());
    dispatch(reset_production_plan());
  }, []);

  function fetchSOList(pg, epp) {
    setDataList({ ...dataList, data: [], loading: true });
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
    };

    if(startDate && endDate){
      payload.startDate = convertDateYYMMDD(new Date(startDate), "-");
      payload.endDate = convertDateYYMMDD(new Date(endDate), "-");
    };

    if (filterConfig?.customer_name?.length > 0) {
      payload.customer_name = JSON.stringify(
        filterConfig.customer_name.map((item) => item.organization)
      );
    } else {
      delete payload.customer_name;
    }

    if (filterConfig?.gst_number?.length > 0) {
      //  payload.gst = filterConfig.gst[0].gst;
      payload.gst_number = JSON.stringify(
        filterConfig.gst_number.map((item) => item.gst)
      );
    } else {
      delete payload.gst_number;
    }

    if (filterConfig?.stateName?.length > 0) {
      // payload.state = filterConfig.state[0].stateName;
      payload.state = JSON.stringify(
        filterConfig.stateName.map((item) => item.stateName)
      );
    } else {
      delete payload.stateName;
    }

    if (filterConfig?.so_status?.length > 0) {
      payload.so_status = filterConfig.so_status[0].status_name;
    } else {
      delete payload.so_status;
    }

    if (filterConfig?.productName?.length > 0) {
      //  payload.productName = filterConfig.productName[0].productName;
      payload.productName = JSON.stringify(
        filterConfig.productName.map((item) => item.productName)
      );
    } else {
      delete payload.productName;
    }

    if (filterConfig?.startPrice && filterConfig?.endPrice) {
      payload.startPrice = filterConfig.startPrice;
      payload.endPrice = filterConfig.endPrice;
    } else {
      delete payload.startPrice;
      delete payload.endPrice;
    }

    getSalesOrder(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        // setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });

        // if(err.response.status == 400){
        //   toast.error(err.response.data.message, {
        //     autoClose: 2000,
        //   });
        //   setPriceRange({from: 0, to: 100});
        // }
      }
    );
  }

  const delSO = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteSalesOrder({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Sales order deleted successfully", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchSOList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchSOList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function fetchCustomers() {
    setCustomerList({ ...customerList, loading: true, data: [] });
    setGST_list({ data: [], loading: true });
    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== null
        );
        const arr = res.data.data.filter(
          (item) =>
            item.gst !== null && item.gst !== undefined && item.gst !== ""
        );
        setGST_list({ data: arr, loading: false });
        setCustomerList({
          ...customerList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setCustomerList({ ...customerList, loading: false, data: [] });
        setGST_list({ data: [], loading: false });
        // toast.error("Something went wrong in suppliers!", {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function fetchSOStatus() {
    setSOStatusList({ ...soStatusList, data: [], loading: true });
    getSOStatus().then(
      (res) => {
        // console.log("res", res);
        const result = res.data.data?.filter(
          (item) =>
            item.status_name !== null &&
            item.status_name !== undefined &&
            item.status_name !== ""
        );
        setSOStatusList({
          ...soStatusList,
          data: result,
          loading: false,
        });
      },
      (err) => {
        setSOStatusList({ ...soStatusList, data: [], loading: false });
      }
    );
  }

  function fetchProdList() {
    setProd_List({ ...prod_List, loading: true, data: [] });

    get_product_light().then(
      (res) => {
        let result = res.data.data.filter(
          (item) =>
            item.productName !== null &&
            item.productName !== undefined &&
            item.productName !== ""
        );
        setProd_List({ ...prod_List, loading: false, data: result });
      },
      (err) => { }
    );
  }

  function fetch_state() {
    setState_list({ data: [], loading: true });

    const payload = {
      country_id:
        process.env.REACT_APP_ENV == "development"
          ? "faa26b30-9669-44e6-b943-e594026dd2ba"
          : "a0599863-9521-4e78-8654-4d49918f943b",
    };
    get_state(payload).then(
      (res) => {
        let arr = res.data.data.filter(
          (item) =>
            item.stateName !== null &&
            item.stateName !== undefined &&
            item.stateName !== ""
        );
        setState_list({ data: arr, loading: false });
      },
      (err) => {
        setState_list({ data: [], loading: false });
      }
    );
  }

  function clearFilter() {
    let obj = {
      customer_name: [], //array
      so_status: [], //string
      startPrice: "",
      endPrice: "",
      gst_number: [], //array
      productName: [], //array
      stateName: [],
    };
    setFilterConfig({ ...obj });
    setPriceRange({ from: 1, to: 1000 });
    fetchCustomers();
    fetchProdList();
    fetch_state();
    fetchSOStatus();
    setStartDate(null);
    setEndDate(null);
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);

      fetchCustomers();
      fetchProdList();
      fetch_state();
      fetchSOStatus();
    }
  }

   async function goToDownload() {
      setLockBtn(true);
  
      const payload = {};

      if(startDate && endDate){
        payload.startDate = convertDateYYMMDD(new Date(startDate), "-");
        payload.endDate = convertDateYYMMDD(new Date(endDate), "-");
      };
  
      await get_prod_report_fashion(payload).then(
        (res) => {
          //
          if (res) {
            base64ToExcel(res.data);
  
          }
        },
        (err) => {
          toast.error(err.message, {
            autoClose: 2000,
          });
        }
      );
    }
  
    function base64ToExcel(response) {
      const { statusCode, headers, body, isBase64Encoded } = response;
  
      // Assume base64String contains the base64 representation of the Excel file
      const base64String = body; // Replace this with your base64 string
  
      // Convert the base64 string into a Uint8Array
      const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
  
      // Create a Blob object from the Uint8Array
      const blob = new Blob([bytes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      // Create a URL for the Blob object
      const url = URL.createObjectURL(blob);
  
      // Create a link element with download attribute
      const link = document.createElement("a");
      link.href = url;
      const currentDate = new Date().toDateString().split(" ").join("_");
      link.download = `ProductionReport_${currentDate}.xlsx`;
  
      // Programmatically click on the link to trigger the download
      document.body.appendChild(link);
      link.click();
  
      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
        setLockBtn(false);
    }

    const handleFromDateChange = (date) => {
      if (endDate && setTimeToZero(date) > setTimeToZero(endDate)) {
        return;
      }
      setStartDate(date);
    };
  
    const handleToDateChange = (date) => {
      if (date === null) {
        setEndDate(null); // Allow clearing the end date
        return;
      }
      if (startDate && setTimeToZero(date) < setTimeToZero(startDate)) {
        return;
      }
      setEndDate(date);
    };

  return (
    <React.Fragment>
      <ToastContainer />
      <FilesUpload 
        visible={visible}
        setVisible={setVisible}
        so_data={so_data}
      />
      {!privilage.read ? (
        <AccessDenied />
      ) : (
        <>
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => toggleNav()}>
              ×
            </a>

            <div className=" content">

              <div className="filter row">
                <label
                  className="filterLabel mb-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_date_range
                    ? constLabel.lbl_date_range
                    : "Date Range"}
                </label>
                <div className="fromDate col-12 col-md-6">
                  <DatePicker
                    id="fromDate"
                    className="form-control form-control-sm"
                    selected={startDate}
                    showYearDropdown
                    scrollableMonthYearDropdown
                    dateFormat="dd/MM/yyyy"
                    placeholderText="From Date"
                    // onChange={(date) => setStartDate(date)}
                    onChange={(date) => handleFromDateChange(date)}
                    maxDate={new Date()}
                    isClearable
                  />
                </div>

                <div className="toDate col-12 col-md-6">
                  <DatePicker
                    id="toDate"
                    className="form-control form-control-sm"
                    showYearDropdown
                    scrollableMonthYearDropdown
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="To Date"
                    // onChange={(date) => setEndDate(date)}
                    onChange={(date) => handleToDateChange(date)}
                    maxDate={new Date()}
                    isClearable
                  />
                </div>
              </div>

              <div className="form-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_select_customer
                    ? constLabel?.lbl_select_customer
                    : "Select Customer"}
                </label>

                <Multiselect
                  options={customerList.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, customer_name: val });
                    } else {
                      setFilterConfig({ ...filterConfig, customer_name: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, customer_name: e });
                  }}
                  selectedValues={filterConfig.customer_name}
                  displayValue="organization"
                  placeholder="Select customer name"
                  isLoading={customerList.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!customerList.loading && <span className="arrow-icon"></span>}
              </div>

              <div className="form-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {"Select GST"}
                </label>

                <Multiselect
                  options={gst_list.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, gst_number: val });
                    } else {
                      setFilterConfig({ ...filterConfig, gst_number: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, gst_number: e });
                  }}
                  selectedValues={filterConfig.gst_number}
                  displayValue="gst"
                  placeholder="Search by GST number"
                  isLoading={gst_list.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!gst_list.loading && <span className="arrow-icon"></span>}
              </div>

              <div className="form-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {"Select State"}
                </label>

                <Multiselect
                  options={state_list.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, stateName: val });
                    } else {
                      setFilterConfig({ ...filterConfig, stateName: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, stateName: e });
                  }}
                  selectedValues={filterConfig.stateName}
                  displayValue="stateName"
                  placeholder="Search by state"
                  isLoading={state_list.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!state_list.loading && <span className="arrow-icon"></span>}
              </div>

              <div className="form-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {"Select SO Status"}
                </label>

                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="status_name"
                  name="status_name"
                  multiple={false}
                  options={soStatusList.data}
                  isLoading={soStatusList.loading}
                  selected={filterConfig.so_status}
                  onChange={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, so_status: val });
                    } else {
                      setFilterConfig({ ...filterConfig, so_status: [] });
                    }
                  }}
                  placeholder="Search by SO status"
                  filterBy={() => true}
                />
                {!soStatusList.loading && <span className="arrow-icon"></span>}
              </div>

              <div className="from-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {"Select Product "}
                </label>

                <Multiselect
                  options={prod_List.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, productName: val });
                    } else {
                      setFilterConfig({ ...filterConfig, productName: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, productName: e });
                  }}
                  selectedValues={filterConfig.productName}
                  displayValue="productName"
                  placeholder="Search by product"
                  isLoading={prod_List.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!prod_List.loading && <span className="arrow-icon"></span>}
              </div>

              <div>
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  From: {priceRange.from}
                </label>

                <input
                  className="form-range"
                  type="range"
                  min={0}
                  max={100000}
                  step={1000}
                  value={priceRange.from}
                  onChange={(event) => handleSliderChange(event, "from")}
                />
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  To: {priceRange.to}
                </label>
                <input
                  className="form-range"
                  type="range"
                  min={0}
                  max={100000}
                  step={1000}
                  value={priceRange.to}
                  onChange={(event) => handleSliderChange(event, "to")}
                />

                {errorMessage && (
                  <span className="text-danger err-msg">{errorMessage}</span>
                )}
              </div>

              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
          <div className="min-vh-100" id="root_div_main">
            <div className="content-wrapper">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="d-md-flex justify-content-start col-12 col-md-5">
                      <Tooltip id={"open-tooltip"} place="top" />
                      <img
                        src={Slide}
                        height="32"
                        width="32"
                        className="cursor_pointer my-1 me-2"
                        onClick={() => toggleNav()}
                        data-tooltip-id={"open-tooltip"}
                        data-tooltip-content={"Filters"}
                      />
                      <h1 className="bold">
                        {constLabel?.lbl_sales_order
                          ? constLabel.lbl_sales_order
                          : "Sales Order"}
                      </h1>
                    </div>



                    <div className="d-md-flex justify-content-end align-items-center col-12 col-md-7">
                      { privilage.write && (
                          <button
                            className="btn btn-outline-secondary me-0 me-md-2 mt-2 mt-md-0"
                            onClick={() =>
                              navigate(`/pcms/create-proforma-invoice`, {
                                state: checkedItems ,
                              })
                            }
                            disabled={selectedIds.size == 0 ? true : false}
                          >
                            
                            {constLabel?.lbl_create_porforma_invoice
                              ? constLabel.lbl_create_porforma_invoice
                              : "Create Porforma Invoice"}
                          </button>
                        )}
                      {showDropdown ?
                        <Dropdown className=" me-0 me-md-2 mt-2 mt-md-0 px-2">
                          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            Planning Options
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {/* <Dropdown.Item onClick={() =>
                              navigate(`/pcms/save/production_planning`, {
                                state: { so_list: checkedItems },
                              })
                            }
                              disabled={selectedIds.size == 0 ? true : false}>
                              {constLabel?.lbl_create_production_plan
                                ? constLabel.lbl_create_production_plan
                                : "Create Production Plan"}
                            </Dropdown.Item> */}

                    // ** The disabled condition is changed because the porforma invoice able to create with selected items. Also the previous condition is also handled i.e. Production planning is created only for BOM_CREATED status. Changed on 12-03-2025  

                            <Dropdown.Item disabled={(selectedIds.size == 0 ||!checkedItems.every(item=>item?.status?.status_name==BOMCREATED))}
                              onClick={() => {
                                navigate(`/pcms/save/production_planning`, {
                                  state: {
                                    from_location: "material_request",
                                    so_list: checkedItems
                                  },
                                })
                              }}>
                              {/* <FontAwesomeIcon
                                  style={{ color: "#000" }}
                                  icon={faPlus}
                                  size="sm"
                                />{" "} */}
                              {constLabel?.lbl_create_material_plan
                                ? constLabel.lbl_create_material_plan
                                : "Create Material Plan"}</Dropdown.Item>


// ** The disabled condition is changed because the porforma invoice able to create with selected items. Also the previous condition is also handled i.e. Production planning is created only for BOM_CREATED status. Changed on 12-03-2025  

                            <Dropdown.Item disabled={(selectedIds.size == 0 ||!checkedItems.every(item=>item?.status?.status_name==BOMCREATED))}
                              onClick={() => {
                                navigate(`/pcms/save/production_planning`, {
                                  state: {
                                    from_location: "resource_planning",
                                    so_list: checkedItems
                                  },
                                })
                              }}>{constLabel?.lbl_create_resource_plan
                                ? constLabel.lbl_create_resource_plan
                                : "Create Resource Plan"}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        :
                        privilage.write && (
                          <button
                            className="btn btn-secondary me-0 me-md-2 mt-2 mt-md-0"
                            onClick={() =>
                              navigate(`/pcms/save/production_planning`, {
                                state: { so_list: checkedItems },
                              })
                            }
                            // ** The disabled condition is changed because the porforma invoice able to create with selected items. Also the previous condition is also handled i.e. Production planning is created only for BOM_CREATED status. Changed on 12-03-2025  
                            disabled={(selectedIds.size == 0 ||!checkedItems.every(item=>item?.status?.status_name==BOMCREATED))? true : false}
                          >
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faPlus}
                              size="sm"
                            />{" "}
                            {constLabel?.lbl_create_production_plan
                              ? constLabel.lbl_create_production_plan
                              : "Create Production Plan"}
                          </button>
                        )
                      }
                   
                     {/* { console.log("Value",(selectedIds.size == 0 ||!checkedItems.every(item=>item?.status?.status_name==BOMCREATED ))? true : false)} */}
                      {privilage.write && (
                        <button
                          className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                          onClick={() => {
                            if(localData?.industry_type === FASHION || localData?.industry_type === APPAREL ){
                              navigate(
                                `/pcms/salesorder`
                              )
                            } else {
                              navigate(
                                `/pcms/create/sales-order`
                                // { state: dataList.data[0] }
                              )
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faPlus}
                            size="sm"
                          />{" "}
                          {constLabel?.lbl_create_sales_order
                            ? constLabel.lbl_create_sales_order
                            : "Create Sales Order"}
                        </button>
                      )}

                      {(localData?.industry_type === FASHION || localData?.industry_type === APPAREL) && 
                      privilage.report && (
                          <>
                            <Tooltip id={"download-tooltip"} place="top" />
                            <button
                              className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                              data-tooltip-id={"download-tooltip"}
                              data-tooltip-content={
                                constLabel?.lbl_download_report
                                  ? constLabel.lbl_download_report
                                  : "Download Report"
                              }
                              onClick={() => { goToDownload() }}
                              disabled={lockBtn}
                            >
                              <FontAwesomeIcon
                                style={{ color: "#fff" }}
                                icon={faDownload}
                                size="sm"
                              />{" "}
                              {lockBtn && <Spinner animation="border" size="sm" />}
                            </button>
                          </>
                        )
                      }

                      <Tooltip id={"show-tooltip"} place="top" />
                      <div
                        className="dropdown"
                        data-tooltip-id={"show-tooltip"}
                        data-tooltip-content={"Show / Hide Columns"}
                      >
                        <div
                          className="d-md-flex justify-content-start align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                          {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                          <img
                            src={List}
                            height="32"
                            width="32"
                            className="cursor_pointer"
                          />
                        </div>

                        <ul className="dropdown-menu px-2">
                          {showLabel !== undefined && showLabel.length > 0
                            ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {dataList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : dataList.data.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead className="table-grey roboto-bold">
                              <tr className="">
                                <th scope="col">#</th>
                                <th scope="col">{"Sr.No"}</th>
                                {showLabel.map((v, i) =>
                                  v.label === "SO Id" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_so_id
                                        ? constLabel.lbl_so_id
                                        : "SO Id"}
                                    </th>
                                  ) : v.label === "Customer PO Ref" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_party_po_ref
                                        ? constLabel.lbl_party_po_ref
                                        : "Customer PO Ref"}
                                    </th>
                                  ) : v.label === "Order Date" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_order_date
                                        ? constLabel.lbl_order_date
                                        : "Order Date"}
                                    </th>
                                  ) : v.label === "Order Due Date" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_order_due_date
                                        ? constLabel.lbl_order_due_date
                                        : "Order Due Date"}
                                    </th>
                                  ) : v.label === "Invoice To" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_invoice_to
                                        ? constLabel.lbl_invoice_to
                                        : "Invoice To"}
                                    </th>
                                  ) : v.label === "Dispatch To" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_dispatch_to
                                        ? constLabel.lbl_dispatch_to
                                        : "Dispatch To"}
                                    </th>
                                  ) : v.label === "Organization Name" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_organization_name
                                        ? constLabel.lbl_organization_name
                                        : "Organization Name"}
                                    </th>
                                  ) : v.label === "Customer" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_customer_name
                                        ? constLabel.lbl_customer_name
                                        : "Customer"}
                                    </th>
                                  ) : v.label === "Status" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_status
                                        ? constLabel.lbl_status
                                        : "Status"}
                                    </th>
                                  ) : v.label === "Quote Ref" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_quote_ref
                                        ? constLabel.lbl_quote_ref
                                        : "Quote Ref"}
                                    </th>
                                  ) : v.label === "Value" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_value
                                        ? constLabel.lbl_value
                                        : "Value"}
                                    </th>
                                  ) : v.label === "Internal Remark" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_internal_remark
                                        ? constLabel.lbl_internal_remark
                                        : "Internal Remark"}
                                    </th>
                                  ) : null
                                )}
                                <th scope="col" width="10%">
                                  {"Action"}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="roboto">
                              {dataList.data.map((val, ind) => (
                                <tr key={ind}>
                                  {
                                    <td>
                                      <input
                                        className="form-check-input cursor_pointer"
                                        type="checkbox"
                                        checked={selectedIds.has(val.uuid)}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;

                                          // Update the current item's `checked` status
                                          const updatedDataList = {
                                            ...dataList,
                                            data: dataList.data.map(
                                              (item, index) =>
                                                index === ind
                                                  ? {
                                                    ...item,
                                                    checked: isChecked,
                                                  }
                                                  : item
                                            ),
                                            loading: false,
                                          };

                                          // Update the `selectedIds` set based on checkbox state
                                          const updatedSelectedIds = new Set(
                                            selectedIds
                                          );
                                          isChecked
                                            ? updatedSelectedIds.add(val.uuid)
                                            : updatedSelectedIds.delete(val.uuid);

                                          // Merge checked items from all pages
                                          const updatedCheckedItems = [
                                            ...new Map(
                                              [
                                                ...checkedItems, // Existing checked items
                                                ...(isChecked ? [val] : []), // Add or remove the current item based on `isChecked`
                                              ]
                                                .filter((item) =>
                                                  updatedSelectedIds.has(
                                                    item.uuid
                                                  )
                                                ) // Keep only items present in `selectedIds`
                                                .map((item) => [item.uuid, item]) // Use Map to ensure unique items by `uuid`
                                            ).values(),
                                          ];

                                          // Update state
                                          setDataList(updatedDataList);
                                          setSelectedIds(updatedSelectedIds);
                                          setCheckedItems(updatedCheckedItems);
                                        }}
                                      // disabled={
                                      //   // val.status?.status_name !== APPROVED &&
                                      //   val.status?.status_name !== BOMCREATED
                                      // }
                                      />
                                    </td>
                                  }
                                  <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                                  {showLabel.map((item, idx) =>
                                    item.label === "SO Id" && item.flag ? (
                                      <td key={idx}>
                                        {val.so_id != "" && val.so_id !== null
                                          ? val.so_id
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Customer PO Ref" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.party_po_ref != "" &&
                                          val.party_po_ref !== null
                                          ? val.party_po_ref
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Order Date" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.order_date != "" &&
                                          val.order_date !== null
                                          ? convertDate(val.order_date, "-")
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Order Due Date" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.order_due_date != "" &&
                                          val.order_due_date !== null
                                          ? convertDate(val.order_due_date, "-")
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Invoice To" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.invoice_to?.organization != "" &&
                                          val.invoice_to?.organization !== null
                                          ? val.invoice_to?.organization
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Dispatch To" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.dispatch_to?.organization != "" &&
                                          val.dispatch_to?.organization !== null
                                          ? val.dispatch_to?.organization
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Organization Name" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.org_id?.comName != "" &&
                                          val.org_id?.comName !== null
                                          ? val.org_id?.comName
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Customer" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.customer_name != "" &&
                                          val.customer_name != null
                                          ? val.customer_name
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Status" && item.flag ? (
                                      <td key={idx}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <span>
                                            {val.status?.status_name &&
                                              val.status?.status_name !== ""
                                              ? val.status?.status_name
                                              : "NA"}
                                          </span>

                                          <span className="ms-4">
                                            <FontAwesomeIcon
                                              icon={faCircle}
                                              size="lg"
                                              className={`cursor_pointer ${val.status?.status_name ===
                                                APPROVED
                                                ? "ind_green"
                                                : val.status?.status_name ===
                                                  IN_PROGRESS
                                                  ? "ind_yellow"
                                                  : val.status?.status_name ===
                                                    DELIVERED
                                                    ? "ind_orange"
                                                    : val.status?.status_name ===
                                                      COMPLETED
                                                      ? "ind_green_dark"
                                                      : val.status?.status_name ===
                                                        CANCELLED
                                                        ? "ind_red"
                                                        : val.status?.status_name ===
                                                          BOMCREATED
                                                          ? "ind_sea_green"
                                                          : "ind_blue"
                                                }`}
                                              data-tooltip-id={
                                                "indicat-tooltip" + idx
                                              }
                                            />
                                            <Tooltip
                                              id={"indicat-tooltip" + idx}
                                              place="left"
                                            >
                                              <div className="bold">
                                                <FontAwesomeIcon
                                                  icon={faCircle}
                                                  size="1x"
                                                  className="ind_blue"
                                                />{" "}
                                                - {OPEN}
                                              </div>
                                              <div className="bold">
                                                <FontAwesomeIcon
                                                  icon={faCircle}
                                                  size="1x"
                                                  className="ind_green"
                                                />{" "}
                                                - {APPROVED}
                                              </div>
                                              <div className="bold">
                                                <FontAwesomeIcon
                                                  icon={faCircle}
                                                  size="1x"
                                                  className="ind_yellow"
                                                />{" "}
                                                - {IN_PROGRESS}
                                              </div>
                                              <div className="bold">
                                                <FontAwesomeIcon
                                                  icon={faCircle}
                                                  size="1x"
                                                  className="ind_orange"
                                                />{" "}
                                                - {DELIVERED}
                                              </div>
                                              <div className="bold">
                                                <FontAwesomeIcon
                                                  icon={faCircle}
                                                  size="1x"
                                                  className="ind_green_dark"
                                                />{" "}
                                                - {COMPLETED}
                                              </div>
                                              <div className="bold">
                                                <FontAwesomeIcon
                                                  icon={faCircle}
                                                  size="1x"
                                                  className="ind_red"
                                                />{" "}
                                                - {CANCELLED}
                                              </div>
                                              <div className="bold">
                                                <FontAwesomeIcon
                                                  icon={faCircle}
                                                  size="1x"
                                                  className="ind_sea_green"
                                                />{" "}
                                                - {BOMCREATED}
                                              </div>
                                            </Tooltip>
                                          </span>
                                        </div>
                                      </td>
                                    ) : item.label === "Quote Ref" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.quote_ref != "" &&
                                          val.quote_ref != null
                                          ? val.quote_ref
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Value" && item.flag ? (
                                      <td key={idx}>
                                        {val.final_total != "" &&
                                          val.final_total != null
                                          ? val.final_total
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Internal Remark" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.internal_remark != "" &&
                                          val.internal_remark != null
                                          ? val.internal_remark
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  <td>
                                    {privilage.write && (
                                      <>
                                        <Tooltip
                                          id={"edit-tooltip" + ind}
                                          place="top"
                                        />
                                        <Edit
                                          data-tooltip-id={"edit-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_edit_so
                                              ? constLabel.lbl_edit_so
                                              : "Edit SO"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            if(localData?.industry_type === FASHION || localData?.industry_type === APPAREL ){
                                              navigate(
                                                `/pcms/salesorder`,
                                                { state: {
                                                  action: "update",
                                                  data: val,
                                                }}
                                              )
                                            } else {
                                              navigate(
                                                `/pcms/update/sales-order`,
                                                { state: val }
                                              )
                                            }
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.read && (
                                      <>
                                        <Tooltip
                                          id={"view-tooltip" + ind}
                                          place="top"
                                        />
                                        <Eye
                                          data-tooltip-id={"view-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_view_so
                                              ? constLabel.lbl_view_so
                                              : "View SO"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() =>
                                            navigate(
                                              `/pcms/view/sales-order/${val.so_id}`,
                                              { state: val }
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                    {privilage.delete && (
                                      <>
                                        <Tooltip
                                          id={"delete-tooltip" + ind}
                                          place="top"
                                        />
                                        <Trash2
                                          data-tooltip-id={
                                            "delete-tooltip" + ind
                                          }
                                          data-tooltip-content={
                                            constLabel?.lbl_delete_so
                                              ? constLabel.lbl_delete_so
                                              : "Delete SO"
                                          }
                                          className="menu_icon_red cursor_pointer me-2"
                                          size={20}
                                          onClick={() => delSO(val)}
                                        />
                                      </>
                                    )}
                                    {/* <Tooltip id={"pdf-tooltip" + ind} place="top" />
                                  <div
                                    data-tooltip-id={"pdf-tooltip" + ind}
                                    data-tooltip-content="Generate PDF"
                                    className="downloadIcon m-1 cursor_pointer"
                                    onClick={() => {
                                      navigate(
                                        `/pcms/view/purchase-order-pdf/${val.voucher_no}`,
                                        { state: val }
                                      );
                                      
                                    }}
                                  >
                                    <FileText color="#FFFFFF" size={18} />
                                  </div> */}

                                    {privilage.read && (
                                      <>
                                        <Tooltip
                                          id={"pdf-tooltip" + ind}
                                          place="top"
                                        />
                                        <FileText
                                          data-tooltip-id={"pdf-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_generate_pdf
                                              ? constLabel.lbl_generate_pdf
                                              : "Generate PDF"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            navigate(
                                              `/pcms/sales-order-pdf`,
                                              { state: val }
                                            );
                                          }}
                                        />
                                      </>
                                    )}

                                    {((localData?.industry_type).toUpperCase() === (FASHION).toUpperCase()) && privilage.write && (
                                      <>
                                        <Tooltip
                                          id={"image-tooltip" + ind}
                                          place="top"
                                        />
                                        <Image
                                          data-tooltip-id={"image-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_design_sheet
                                              ? constLabel.lbl_design_sheet
                                              : "Design Sheet"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            setVisible(true);
                                            set_so_data(val);
                                          }}
                                        />
                                      </>
                                    )}

                                    {((localData?.industry_type).toUpperCase() === "FASHION") &&
                                      privilage.write &&
                                      val.sales_order_type !== "Alteration" && (
                                        <>
                                          <Tooltip id={"alter-tooltip" + ind} place="top" />
                                          <Scissors
                                            data-tooltip-id={"alter-tooltip" + ind}
                                            data-tooltip-content={
                                              constLabel?.lbl_alteration ? constLabel.lbl_alteration : "Alteration"
                                            }
                                            className="menu_icon_grey cursor_pointer me-2"
                                            size={20}
                                            onClick={() => {
                                              navigate(`/pcms/alt-so`, { state: val });
                                            }}
                                          />
                                        </>
                                      )}

                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <p className="mb-0">
                            Show
                            <select
                              className="mx-1"
                              defaultValue={entriesPerPage}
                              onChange={(e) => {
                                setEntriesPerPage(e.target.value);
                                fetchSOList(1, e.target.value);
                              }}
                            >
                              <option value={"10"}>10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                            Entries
                          </p>
                          <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                            } to ${Math.min(
                              parseInt(entriesPerPage) + parseInt(srNo),
                              dataList.totalRecords
                            )} of ${dataList.totalRecords} entries`}</p>
                          <Pagination className="my-2">
                            <Pagination.Prev
                              disabled={page === 1 ? true : false}
                              onClick={() => {
                                fetchSOList(
                                  page - 1,
                                  entriesPerPage,
                                  parseInt(srNo) - parseInt(entriesPerPage)
                                );
                                setSrNo((prevC) =>
                                  page - 1 == 1
                                    ? 0
                                    : prevC - parseInt(entriesPerPage)
                                );
                                setPage(page - 1);
                              }}
                            >
                              {"Prev"}
                            </Pagination.Prev>

                            <Pagination.Item active>{page}</Pagination.Item>

                            <Pagination.Next
                              disabled={
                                page === maxPage ||
                                  maxPage === 0 ||
                                  entriesPerPage > dataList.data.length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                fetchSOList(
                                  page + 1,
                                  entriesPerPage,
                                  parseInt(srNo) + parseInt(entriesPerPage)
                                );
                                setSrNo(
                                  (prevC) => prevC + parseInt(entriesPerPage)
                                );
                                setPage(page + 1);
                              }}
                            >
                              {"Next"}
                            </Pagination.Next>
                          </Pagination>
                        </div>
                      </>
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        No Sales Orders Found !
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default SalesOrder;
