
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faAdd,
    faEye,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Trash2 } from "react-feather";
import {
    getProdList,
    getUOM,
    createBOM,
    getProduct_Family,
    getBOM,
    get_product_light,
} from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";

const schema = yup
    .object()
    .shape({
        product: yup.array().notRequired(),
        productFamily: yup.array().notRequired(),

        quantity: yup
            .string()
            .required("Quantity is required")
            // .matches(/^\d+$/, "Enter valid quantity")
            .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
            .typeError("Enter valid quantity"),
        // .integer('Enter valid quantity'),
        bomType: yup.string().required("Please select bill of material"),
        bom_name: yup.string().required("Bom name is required!"),
        remarks: yup.string().required("Remark is required"),
        items: yup.array().of(
            yup.object().shape({
                prodFam: yup.array().notRequired(),
                component: yup.array().notRequired(),
                quantity: yup
                    .string()
                    .required("Quantity is required")
                    // .matches(/^\d+$/, "Enter valid quantity")
                    .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
                    .typeError("Enter valid quantity"),
                unitOfMeasure: yup
                    .array()
                    .min(1, "Please select unit of measure")
                    .required("Please select unit of measure"),
            })
        ),
    })
    .required();


function CreateBom(props) {
    const { modalData, setModalData, preparingBomList, consolidate, fetch_bom_list } = props;
    let navigate = useNavigate();
    let location = useLocation();

    const {
        register,
        handleSubmit,
        formState,
        control,
        reset,
        watch,
        setValue,
        getValues,
        setError,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            items: [{}],
        },
    });

    let { errors } = formState;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });

    const [prodList, setProdList] = useState({ data: [], loading: false });
    const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
    const [uom, setUom] = useState({ data: [], loading: false });
    const [itemsData, setItemsData] = useState([{ prodFam: [], component: [] }]);
    const [disableBtn, setDisableBtn] = useState(false);

    let masterProd = watch("product");
    let masterProdFam = watch("productFamily");

    const [isProdFam, setIsProdFam] = useState(false);

    useEffect(() => {
        console.log("modalData", modalData);
        setValue("product", [modalData.data], { shouldValidate: true });
        fetchProdList();
        fetchUOM();
        fetchProdFamily();
    }, []);

    useEffect(() => {
        validateProdErr();

        if (masterProd !== undefined && masterProd.length > 0) {
            setValue("productFamily", []);
            fetchBOM(masterProd?.[0]?.productName);
        }
    }, [masterProd]);

    useEffect(() => {
        validateProdErr();

        if (masterProdFam !== undefined && masterProdFam.length > 0) {
            setValue("product", []);
            fetchBOM(masterProdFam?.[0]?.familyname);
        }
    }, [masterProdFam]);

    function validateProdErr() {
        if (masterProd !== undefined) {
            if (itemsData.length > 0) {
                for (let i = 0; i < itemsData.length; i++) {
                    if (itemsData[i].component.length > 0) {
                        if (
                            masterProd.length > 0 &&
                            masterProd[0].uuid == itemsData[i].component[0].uuid
                        ) {
                            setError(`items[${i}].component`, {
                                type: "custom",
                                message: "Master product and line product cannot be same !",
                            });
                        } else {
                            clearErrors(`items[${i}].component`);
                        }
                    }
                }
            }
        }

        if (masterProdFam !== undefined) {
            if (itemsData.length > 0) {
                for (let i = 0; i < itemsData.length; i++) {
                    if (itemsData[i].prodFam.length > 0) {
                        if (
                            masterProdFam.length > 0 &&
                            masterProdFam[0].uuid == itemsData[i].prodFam[0].uuid
                        ) {
                            setError(`items[${i}].prodFam`, {
                                type: "custom",
                                message:
                                    "Master product Family and line product family cannot be same !",
                            });
                        } else {
                            clearErrors(`items[${i}].prodFam`);
                        }
                    }
                }
            }
        }
    }

    function fetchProdFamily() {
        setProdFamily({ ...prodFamily, data: [], loading: true });
        getProduct_Family().then(
            (res) => {
                let arr = res.data.data.filter(
                    (item) => item.familyname !== null || item.familyname !== undefined
                );
                setProdFamily({ ...prodFamily, data: arr, loading: false });
            },
            (err) => {
                setProdFamily({ ...prodFamily, data: [], loading: false });
            }
        );
    }

    function fetchUOM() {
        setUom({ ...uom, data: [], loading: true });
        getUOM().then(
            (res) => {
                setUom({ ...uom, data: res.data.data, loading: false });
            },
            (err) => {
                setUom({ ...uom, data: [], loading: false });
            }
        );
    }

    function fetchProdList() {
        setProdList({ ...prodList, data: [], loading: true });

        get_product_light().then(
            (res) => {
                let arr = res.data.data.filter(
                    (item) => item.productName !== null || item.productName !== undefined
                );
                setProdList({ ...prodList, data: arr, loading: false });
            },
            (err) => {
                setProdList({ ...prodList, data: [], loading: false });
            }
        );
    }

    const onSubmit = (data) => {
        // console.log("data", data);
        // console.log("itemsData", itemsData);
        setDisableBtn(true);
        validateProdErr();

        if (Object.keys(errors).length == 0) {
            let tempLineItems = [];
            for (let i = 0; i < itemsData.length; i++) {
                if (
                    itemsData[i].component.length == 0 &&
                    itemsData[i].prodFam.length == 0
                ) {
                    setDisableBtn(false);
                    toast.error("Please select either one product family or component", {
                        autoClose: 5000,
                    });
                    return;
                } else {
                    // for (let i = 0; i < data.items.length; i++) {
                    if (itemsData[i].component.length > 0) {
                        tempLineItems.push({
                            product_id: itemsData[i].component[0].uuid,
                            product_type_id: itemsData[i].component[0].product_type_id.uuid,
                            productType: itemsData[i].component[0].productTypename,
                            productId: itemsData[i].component[0].productId,
                            productname: itemsData[i].component[0].productName,
                            qty: data.items[i].quantity,
                            unitname: data.items[i].unitOfMeasure[0].uom,
                            unit_id: data.items[i].unitOfMeasure[0].uuid,
                            unit: data.items[i].unitOfMeasure[0].uomid,
                        });
                    }

                    if (itemsData[i].prodFam.length > 0) {
                        tempLineItems.push({
                            product_family_id: itemsData[i].prodFam[0].uuid,
                            productId: itemsData[i].prodFam[0].familyId,
                            productname: itemsData[i].prodFam[0].familyname,
                            qty: data.items[i].quantity,
                            unitname: data.items[i].unitOfMeasure[0].uom,
                            unit_id: data.items[i].unitOfMeasure[0].uuid,
                            unit: data.items[i].unitOfMeasure[0].uomid,
                        });
                    }
                    // }
                }
            }

            const payload = {
                // "productId": data.product[0].productId,
                // product_family_id: data.product[0].prod_family_id != undefined && data.product[0].prod_family_id != null ?
                //  data.product[0].prod_family_id.uuid : null,

                qty: data.quantity,
                // "productname": data.product[0].productName,
                // "product_id": data.product[0].uuid,
                // "product_type_id": data.product[0].product_type_id.uuid,
                remarks: data.remarks,
                bom_type: data.bomType,
                bom_line_item: tempLineItems,
                bom_name: data.bom_name
            };

            if (data.product.length > 0) {
                payload.product_family_id =
                    data.product[0].prod_family_id != undefined &&
                        data.product[0].prod_family_id != null
                        ? data.product[0].prod_family_id.uuid
                        : null;
                payload.productType = data.product[0].productTypename;
                payload.productname = data.product[0].productName;
                payload.productId = data.product[0].productId;
                payload.product_id = data.product[0].uuid;
                payload.product_type_id = data.product[0].product_type_id?.uuid || data.product[0].product_type_id;
            } else {
                payload.productType = null;
                payload.productname = data.productFamily[0].familyname;
                payload.product_id = null;
                payload.productId = data.productFamily[0].familyId;
                payload.product_type_id = null;
            }

            if (data.productFamily.length > 0) {
                payload.product_family_id = data.productFamily[0].uuid;
            }

            console.log("payload", payload);
            
            createBOM(payload).then(
                (res) => {
                    toast.success("BOM created successfully", {
                        autoClose: 2000,
                    });

                    setTimeout(() => {

                        setDisableBtn(false);
                        
                        if (preparingBomList) {
                            preparingBomList(consolidate, "after_bom_creation");
                        }
                        
                        if (fetch_bom_list) {
                            fetch_bom_list(modalData.data.uuid, true);
                        }
                        setModalData({ ...modalData, state: false, data: null })
                    }, [2000]);
                },
                (err) => {
                    setDisableBtn(false);
                    toast.error(err.response?.data?.message, {
                        autoClose: 2000,
                    });
                }
            );
        }
    };

    function handleProdFam(e, ind) {
        const temp = [...itemsData];

        temp[ind].prodFam = e;
        temp[ind].component = [];
        validateProdErr();
        setItemsData(temp);
        clearErrors(`items[${ind}].component`);
    }

    function handleProdComp(e, ind) {
        const temp = [...itemsData];

        temp[ind].component = e;
        temp[ind].prodFam = [];

        validateProdErr();
        setItemsData(temp);
    }

    function fetchBOM(bom_prod){
        getBOM({productname : bom_prod}).then(
          (res) => {
            // console.log("res", res);
            if (res && res.data.data.length > 0) {
              const latestBomName = getLatestBom(res.data.data);
              const latest_name = getNextBomName(latestBomName, bom_prod);
              setValue("bom_name", latest_name);
            } 
          },
          (err) => {
            // console.log("err", err);
            if(err.response.status == 404 && err.response.data.data.length == 0){
              setValue("bom_name", `${bom_prod}-BOM-01`);
            }
          }
        );
      }
    
      function getLatestBom(bomList){
        return bomList.reduce((latest, current) => 
          new Date(current.created_at) > new Date(latest.created_at) ? current : latest
        );
      };
    
      function getNextBomName(latestBom, baseName){
        if (!latestBom || !latestBom.bom_name) {
          // If latestBom has no value, use base name with "-01"
          return `${baseName}-BOM-01`;
        }
      
        // Split bom_name from the hyphen
        const [name, mid, number] = latestBom.bom_name.split('-');
      
        // Increment the number by 1, or default to 1 if no valid number
        const nextNumber = parseInt(number) + 1 || 1;
      
        // Return the new name with incremented number
        return `${name}-${mid}-${nextNumber.toString().padStart(2, '0')}`;
      };

    return (
        <React.Fragment>
            <Modal show={modalData.state} onHide={() =>
                setModalData({ ...modalData, state: false, data: null })} size={"xl"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>
                        {"Create Bill of Material"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <p className="mb-3 d-none">
                            <i>
                                {" "}
                                <span className="text-danger roboto-bold">Note: </span>
                                Either select product family or product
                            </i>
                        </p>

                        <div
                            className="form-check form-switch d-none"
                            style={{ paddingLeft: "3rem" }}
                        >
                            <label
                                className="form-check-label lbl-style roboto-bold"
                                htmlFor="flexSwitchCheckChecked"
                            >
                                {"Product Name / Product Family"}
                            </label>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                checked={isProdFam}
                                onChange={() => {
                                    setIsProdFam(!isProdFam);
                                }}

                            />
                        </div>

                        {isProdFam == false ?
                            <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label col-12 col-md-12 roboto-bold"
                                >
                                    {"Product "}
                                </label>

                                <div className="col-12 col-md-9">
                                    <Controller
                                        name={`product`}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="productName"
                                                multiple={false}
                                                options={prodList.data}
                                                isLoading={prodList.loading}
                                                placeholder="Select Product"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.product ? true : false}
                                                disabled
                                            />
                                        )}
                                    />
                                    {errors.product && (
                                        <span className="text-danger err-msg">
                                            {errors.product.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            :
                            <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label col-12 col-md-12 roboto-bold"
                                >
                                    {"Product Family"}
                                </label>

                                <div className="col-12 col-md-9">
                                    <Controller
                                        name={`productFamily`}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="familyname"
                                                multiple={false}
                                                options={prodFamily.data}
                                                isLoading={prodFamily.loading}
                                                placeholder="Select Product Family"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.productFamily ? true : false}
                                            />
                                        )}
                                    />
                                    {errors.productFamily && (
                                        <span className="text-danger err-msg">
                                            {errors.productFamily.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        }

                        <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label col-12 col-md-12 roboto-bold"
                            >
                                {"Bom Name"}
                                <span className="text-danger"> *</span>
                            </label>

                            <div className="col-12 col-md-9">
                                <input
                                    type="text"
                                    className={
                                        errors.bom_name
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    {...register("bom_name")}
                                    placeholder="Enter bom name"
                                />

                                {errors.bom_name && (
                                    <span className="text-danger err-msg">
                                        {errors.bom_name.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label col-12 col-md-12 roboto-bold"
                            >
                                {"Quantity"}
                                <span className="text-danger">*</span>
                            </label>

                            <div className="col-12 col-md-9">
                                <input
                                    type="text"
                                    className={
                                        errors.quantity
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    {...register("quantity")}
                                    placeholder="Enter Quantity"
                                />

                                {errors.quantity && (
                                    <span className="text-danger err-msg">
                                        {errors.quantity.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label col-12 col-md-3 roboto-bold"
                            >
                                {"BOM Type"}
                                <span className="text-danger">*</span>
                            </label>

                            <div className="col-12 col-md-9">
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="bomType"
                                        id="exampleRadios1"
                                        value="Manufacture this product"
                                        defaultChecked
                                        {...register("bomType")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios1"
                                    >
                                        Manufacture this product
                                    </label>
                                </div>
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="bomType"
                                        id="exampleRadios2"
                                        value="Kit"
                                        {...register("bomType")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                    >
                                        Kit
                                    </label>
                                </div>
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="bomType"
                                        id="exampleRadios3"
                                        value="Subcontracting"
                                        {...register("bomType")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios3"
                                    >
                                        Subcontracting
                                    </label>
                                </div>

                                {errors.bomType && (
                                    <span className="text-danger err-msg">
                                        {errors.bomType.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label col-12 col-md-12 roboto-bold"
                            >
                                {"Remark"}
                                <span className="text-danger">*</span>
                            </label>

                            <div className="col-12 col-md-9">
                                <textarea
                                    className={
                                        errors.remarks
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    rows={"2"}
                                    {...register("...remarks")}
                                    placeholder="Enter remark"
                                ></textarea>

                                {errors.remarks && (
                                    <span className="text-danger err-msg">
                                        {errors.remarks.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12">
                            <div
                                className=" py-3"
                            // style={{ overflowX: "scroll" }}
                            >
                                <table className="table table-responsive table-bordered">
                                    <thead className="thead-light text-center">
                                        <tr>
                                            <th scope="col">
                                                {"Product Family"}
                                            </th>
                                            <th scope="col">
                                                {"Product"}
                                            </th>
                                            <th scope="col">
                                                {"Quantity"}
                                            </th>
                                            <th scope="col">
                                                {"Product Unit of Measure"}
                                            </th>
                                            <th scope="col">{"Action"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map(({ id }, index) => (
                                            <tr key={id} className="f-18">
                                                <td>
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        labelKey="familyname"
                                                        multiple={false}
                                                        options={prodFamily.data}
                                                        isLoading={prodFamily.loading}
                                                        placeholder="Select product family"
                                                        onChange={(e) => {
                                                            handleProdFam(e, index)
                                                            setValue(`items[${index}].unitOfMeasure`, [], { shouldValidate : true})
                                                          }}
                                                        isInvalid={
                                                            errors.items?.[index]?.prodFam ? true : false
                                                        }
                                                        selected={itemsData[index].prodFam}
                                                    />

                                                    {errors.items?.[index]?.prodFam && (
                                                        <span className="text-danger err-msg">
                                                            {errors.items?.[index]?.prodFam.message}
                                                        </span>
                                                    )}

                                                    {index + 1 == fields.length ? (
                                                        <div>
                                                            <button
                                                                style={{ fontSize: "16px" }}
                                                                className="btn btn-sm button-primary px-2 mt-2"
                                                                onClick={() => {
                                                                    let temp = [...itemsData];
                                                                    temp.push({ prodFam: [], component: [] });
                                                                    setItemsData(temp);
                                                                    append({});
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        color: "#ffffff",
                                                                        marginRight: "5px",
                                                                    }}
                                                                    icon={faAdd}
                                                                    size="sm"
                                                                />{" "}
                                                                {"Add"}
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        labelKey="productName"
                                                        multiple={false}
                                                        options={prodList.data}
                                                        isLoading={prodList.loading}
                                                        placeholder="Select product"
                                                        onChange={(e) => {
                                                            handleProdComp(e, index)
                                                            if(e.length > 0){
                                                              setValue(`items[${index}].unitOfMeasure`,[e?.[0]?.unit_id], { shouldValidate : true})
                                                            } else {
                                                              setValue(`items[${index}].unitOfMeasure`, [], { shouldValidate : true})
                                                            }
                                                          }}
                                                        isInvalid={
                                                            errors.items?.[index]?.component
                                                                ? true
                                                                : false
                                                        }
                                                        selected={itemsData[index].component}
                                                    />

                                                    {errors.items?.[index]?.component && (
                                                        <span className="text-danger err-msg">
                                                            {errors.items?.[index]?.component.message}
                                                        </span>
                                                    )}
                                                </td>

                                                <td style={{ verticalAlign: "top" }}>
                                                    <input
                                                        className={
                                                            errors.items?.[index]?.quantity
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                        type="text"
                                                        name={`items[${index}].quantity`}
                                                        {...register(`items[${index}].quantity`)}
                                                        // onChange={(e) => handleFileChange(e, index)}
                                                        placeholder="Enter quantity"
                                                    />

                                                    {errors.items?.[index]?.quantity && (
                                                        <span className="text-danger err-msg">
                                                            {errors.items?.[index]?.quantity.message}
                                                        </span>
                                                    )}
                                                </td>

                                                <td style={{ verticalAlign: "top" }}>
                                                    <Controller
                                                        name={`items[${index}].unitOfMeasure`}
                                                        control={control}
                                                        render={({
                                                            field: { onChange, onBlur, value, ref },
                                                        }) => (
                                                            <Typeahead
                                                                id="basic-typeahead-single"
                                                                labelKey="uom"
                                                                multiple={false}
                                                                options={uom.data}
                                                                isLoading={uom.loading}
                                                                placeholder="Select Unit Of Measure"
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                selected={value}
                                                                isInvalid={
                                                                    errors.items?.[index]?.unitOfMeasure
                                                                        ? true
                                                                        : false
                                                                }
                                                                disabled={itemsData[index]?.component?.length > 0 ? true : false}
                                                            />
                                                        )}
                                                    />

                                                    {errors.items?.[index]?.unitOfMeasure && (
                                                        <span className="text-danger err-msg">
                                                            {errors.items?.[index]?.unitOfMeasure.message}
                                                        </span>
                                                    )}
                                                </td>

                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        verticalAlign: "top",
                                                        width: "10%",
                                                    }}
                                                >
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {"Delete BOM Item"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div
                                                            className="deleteIcon cursor_pointer"
                                                            onClick={
                                                                index > 0
                                                                    ? () => {
                                                                        remove(index);
                                                                        let temp = [...itemsData];
                                                                        temp.splice(index, 1);

                                                                        setItemsData(temp);
                                                                    }
                                                                    : null
                                                            }
                                                        >
                                                            <Trash2 color="#FFFFFF" size={18} />
                                                        </div>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow "
                        disabled={disableBtn}
                    >
                        {"Save"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    )
}

export default CreateBom