import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  faDownload,
  faPlus,
  faUpload,
  faBan,
  faFilter,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import {
  getProdList,
  deleteProduct,
  getSupplier,
  getPO,
  deletePO,
  import_po,
  get_state,
  getPOStatus,
  getProductType,
  get_po_template,
  get_product_light,
} from "../../../api/index";
import {
  calMaxPage,
  calMaxPage_new,
  convertDate,
  convertDateYYMMDD,
  getLabels,
  isExcelFile,
  setAccessPrivilage,
  getFinancialYearStartDate,
  setTimeToZero
} from "../../../common/Functions/CommonFunctions";
import { Pagination, Spinner } from "react-bootstrap";
import { Eye, Trash2, Copy, FileText, Edit } from "react-feather";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getPOTemplate } from "../../../constant/index";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import Multiselect from "multiselect-react-dropdown";
import { Purchase_Order_Col_name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../../assets/images/icons/project_menu/collapse.png"

function PurchaseOrd() {
  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });
  const [po_list, setPolist] = useState({
    data: [],
    loading: false,
  });
  const [gst_list, setGST_list] = useState({
    data: [],
    loading: false,
  });
  const [state_list, setState_list] = useState({
    data: [],
    loading: false,
  });

  const [selProd, setSelProd] = useState({});
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({ value: "", status: false });
  const [reset, setReset] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    suppliername: [], //array
    client_poid: [], //array
    po_status: [], //string
    startPrice: "",
    endPrice: "",
    voucher_no: "",
    quote_ref: "",
    gst: [], //array
    productName: [], //array
    stateName: [],
    productType: [],
  });
  const [constLabel, setConstLabel] = useState({});
  // const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [poStatusList, setPoStatusList] = useState({
    data: [],
    loading: false,
  });
  const [priceRange, setPriceRange] = useState({ from: 1, to: 1000 });
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [products, setProducts] = useState({ data: [], loading: false });
  const [isChecked, setIsChecked] = useState(false);

  const [showLabel, setShowLabel] = useState(Purchase_Order_Col_name);
  const [errorMessage, setErrorMessage] = useState("");

  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const [importData, setImportData] = useState(false);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const handleCheckboxChange = () => {
    // Toggle the isChecked state when the checkbox is changed
    let val = !isChecked;
    if (val) {
      setFilterConfig({ ...filterConfig, po_inwarded: "True" });
    } else {
      let obj = { ...filterConfig };
      delete obj.po_inwarded;
      setFilterConfig({ ...obj });
    }
    setIsChecked(!isChecked);
  };

  const handleSliderChange = (event, sliderName) => {
    const value = parseInt(event.target.value, 10);
    let obj = {};
    // Reset error message
    setErrorMessage("");

    if (sliderName == "from") {
      obj.startPrice = value;
      obj.endPrice = priceRange.to;

      // Check if startPrice is greater than or equal to endPrice, then set error message
      if (value >= priceRange.to) {
        setErrorMessage("From price must be less than To price");
        return;
      }
    } else {
      obj.endPrice = value;
      obj.startPrice = priceRange.from;

      // Check if endPrice is less than or equal to startPrice, then set error message
      if (value <= priceRange.from) {
        setErrorMessage("To price must be greater than From price");
        return;
      }
    }
    setPriceRange({ ...priceRange, [sliderName]: value });
    setFilterConfig({ ...filterConfig, ...obj });
  };
  const { control } = useForm({ mode: "onChange" }); // initialise the hook

  let navigate = useNavigate();
  // const handleSliderChange = (event, sliderName) => {
  //   const value = parseInt(event.target.value, 10);
  //   let obj = {};
  //   if (sliderName == "from") {
  //     obj.startPrice = value;
  //     obj.endPrice = priceRange.to;
  //   } else {
  //     obj.endPrice = value;
  //     obj.startPrice = priceRange.from;
  //   }
  //   setPriceRange({ ...priceRange, [sliderName]: value });
  //   setFilterConfig({ ...filterConfig, ...obj });
  // };

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Purchase Order"));
  }, []);

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Purchase Order"));
    const debounceTimeout = setTimeout(() => {
      fetchPOList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  // useEffect(() => {
  //   fetchSuppliers();
  //   // fetchPolist();
  //   fetch_state();
  //   fetchPOStatus();
  //   fetchProductType();
  //   fetchProdList();
  // }, []);

  function fetchPolist() {
    setPolist({ data: [], loading: true });
    getPO().then(
      (res) => {
        const arr = res.data.data.filter(
          (item) =>
            item.client_poid !== null &&
            item.client_poid !== undefined &&
            item.client_poid !== ""
        );
        setPolist({ data: arr, loading: false });
      },
      (err) => {
        setPolist({ data: [], loading: false });
      }
    );
  }

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function fetchPOList(pg, epp) {

    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      // startDate: convertDateYYMMDD(new Date(startDate), "-"),
      // endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    setDataList({ ...dataList, data: [], loading: true });

    // Include date range only if both startDate and endDate are selected
    if (startDate && endDate) {
      payload.startDate = convertDateYYMMDD(new Date(startDate), "-");
      payload.endDate = convertDateYYMMDD(new Date(endDate), "-");
    }

    if (filterConfig?.suppliername?.length > 0) {
      // payload.suppliername = filterConfig.suppliername[0].name;
      payload.suppliername = JSON.stringify(
        filterConfig.suppliername.map((item) => item.name)
      );
    } else {
      delete payload.suppliername;
    }

    if (filterConfig?.client_poid.length > 0) {
      //  payload.client_poid = filterConfig.client_poid[0].client_poid;

      payload.client_poid = JSON.stringify(
        filterConfig.client_poid.map((item) => item.client_poid)
      );
    } else {
      delete payload.client_poid;
    }

    if (filterConfig?.gst?.length > 0) {
      //  payload.gst = filterConfig.gst[0].gst;
      payload.gst = JSON.stringify(filterConfig.gst.map((item) => item.gst));
    } else {
      delete payload.gst;
    }

    if (filterConfig?.stateName?.length > 0) {
      // payload.state = filterConfig.state[0].stateName;
      payload.state = JSON.stringify(
        filterConfig.stateName.map((item) => item.stateName)
      );
    } else {
      delete payload.state;
    }

    if (filterConfig?.po_status?.length > 0) {
      payload.po_status = filterConfig.po_status[0].postatus;
    } else {
      delete payload.po_status;
    }
    if (filterConfig?.productType?.length > 0) {
      payload.producttype = JSON.stringify(
        filterConfig.productType.map((item) => item.productType)
      );
    } else {
      delete payload.producttype;
    }

    if (filterConfig?.productName?.length > 0) {
      //  payload.productName = filterConfig.productName[0].productName;
      payload.productname = JSON.stringify(
        filterConfig.productName.map((item) => item.productName)
      );
    } else {
      delete payload.productname;
    }

    if (filterConfig?.startPrice && filterConfig?.endPrice) {
      payload.startPrice = filterConfig.startPrice;
      payload.endPrice = filterConfig.endPrice;
    } else {
      delete payload.startPrice;
      delete payload.endPrice;
    }

    if (filterConfig.po_inwarded) {
      payload.po_inwarded = filterConfig.po_inwarded;
    }

    if (filterConfig.voucher_no !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.voucher_no = filterConfig.voucher_no.trim();

    } else {
      delete payload.voucher_no;
    }

    if (filterConfig.quote_ref !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.quote_ref = filterConfig.quote_ref.trim();

    } else {
      delete payload.quote_ref;
    }



    getPO(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));
        //console.log('ma',calMaxPage_new(parseInt(res.data.total),epp))
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }
  //console.log("max",maxPage);

  function fetchProdList() {
    setProducts({ data: [], loading: true });
    get_product_light().then(
      (res) => {
        //data: res.data.data,
        let result = res.data.data.filter(
          (item) =>
            item.productName !== null &&
            item.productName !== undefined &&
            item.productName !== ""
        );
        setProducts({ data: result, loading: false });
      },
      (err) => {
        setProducts({ data: [], loading: false });
      }
    );
  }

  const delPO = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deletePO({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Purchase order deleted successfully", {
              autoClose: 2000,
            });

            if (dataList.data.length == 1 && page > 1) {
              fetchPOList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchPOList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file

    // setSelectedFile(file);

    if (isExcelFile(file.name)) {
      try {
        if (file) {
          const formData = new FormData();
          formData.append("excel_file", file);

          import_po(formData).then(
            (res) => {
              if (res.status === 200 || res.status === 201) {
                toast.success("PO imported successfully !", {
                  autoClose: 3000,
                });
                fetchPOList(1, entriesPerPage);
              }
            },
            (err) => {
              if (err.response.status === 400) {
                toast.error(err.response, {
                  autoClose: 2000,
                });
              }

              if (err.response.status === 409) {
                SweetAlert.fire({
                  title: err.response.data.message,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Ok",
                  cancelButtonText: "cancel",
                  reverseButtons: true,
                }).then((result) => {
                  if (result.value) {
                    formData.append("allow_duplicate", "yes");
                    import_po(formData).then(
                      (res) => {
                        if (res.status === 200 || res.status === 201) {
                          toast.success("PO imported successfully !", {
                            autoClose: 3000,
                          });
                          fetchPOList(1, entriesPerPage);
                        }
                      },
                      (err) => {
                        if (err.response.status === 400) {
                          toast.error(err.response.data?.error?.po_date[0], {
                            autoClose: 2000,
                          });
                          fetchPOList(1, entriesPerPage);
                        }
                      }
                    );
                  }
                });
              }
            }
          );
        }
      } catch (err) {
        toast.error("Something went wrong !", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Import valid excel file !", {
        autoClose: 2000,
      });
    }
  };

  // async function fetchPOTemplate() {
  //   const token = localStorage.getItem("access");
  //   const idtoken = localStorage.getItem("id");

  //   try {
  //     const response = await axios.get(
  //       process.env.REACT_APP_API_URL + getPOTemplate,
  //       {
  //         responseType: "blob", // Set the responseType to ‘arraybuffer’
  //         headers: {
  //           // Auth: `Bearer ${token}`, // Add the token to the Authorization header
  //           "X-AUTH-TOKEN": `${token}`,
  //           "X-ID-TOKEN": `${idtoken}`,
  //         },
  //         // params: payload
  //       }
  //     );

  //     const blob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });

  //     // Create a URL for the Blob
  //     const url = window.URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "PO_Import_Details.xlsx";
  //     document.body.appendChild(link);

  //     link.click();

  //     toast.success("Template downloaded successfully !", {
  //       autoClose: 2000,
  //     });

  //     link.remove();
  //     window.URL.revokeObjectURL(url);
  //   } catch (err) {
  //     toast.error("Something went wrong !", {
  //       autoClose: 2000,
  //     });
  //   }
  // }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `Purchase_Order_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    setDownloadTemplate(false);
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }


  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function clearFilter() {
    let obj = {
      suppliername: [],
      client_poid: [],
      po_status: [],
      startPrice: "",
      endPrice: "",
      voucher_no: "",
      quote_ref: "",
      gst: [],
      productName: [],
      stateName: [],
      productType: [],
    };
    delete obj.po_inwarded;
    // setStartDate(getFinancialYearStartDate(new Date()));
    // setEndDate(new Date());
    setStartDate(null);
    setEndDate(null);
    setFilterConfig(obj);
    setPriceRange({ from: 1, to: 1000 });
    setIsChecked(false);

    fetchSuppliers();
    fetchPolist();
    fetch_state();
    fetchPOStatus();
    fetchProductType();
    fetchProdList();
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, loading: true, data: [] });
    setGST_list({ data: [], loading: true });
    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        const arr = res.data.data.filter(
          (item) =>
            item.gst !== null && item.gst !== undefined && item.gst !== ""
        );
        setGST_list({ data: arr, loading: false });
        setSupplierList({
          ...supplierList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setSupplierList({ ...supplierList, loading: false, data: [] });
        setGST_list({ data: [], loading: false });
        // toast.error("Something went wrong in suppliers!", {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function fetch_state() {
    setState_list({ data: [], loading: true });
    const payload = {
      country_id:
        process.env.REACT_APP_ENV == "development"
          ? "faa26b30-9669-44e6-b943-e594026dd2ba"
          : "a0599863-9521-4e78-8654-4d49918f943b",
    };
    get_state(payload).then(
      (res) => {
        let arr = res.data.data.filter(
          (item) =>
            item.stateName !== null &&
            item.stateName !== undefined &&
            item.stateName !== ""
        );
        setState_list({ data: arr, loading: false });
      },
      (err) => {
        setState_list({ data: [], loading: false });
      }
    );
  }

  function fetchPOStatus() {
    setPoStatusList({ ...poStatusList, data: [], loading: true });

    getPOStatus().then(
      (res) => {
        const result = res.data.data.filter(
          (item) =>
            item.postatus !== null &&
            item.postatus !== undefined &&
            item.postatus !== ""
        );
        setPoStatusList({
          ...poStatusList,
          data: result,
          loading: false,
        });
      },
      (err) => {
        setPoStatusList({ ...poStatusList, data: [], loading: false });
      }
    );
  }

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleFromDateChange = (date) => {
    if (endDate && setTimeToZero(date) > setTimeToZero(endDate)) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (date === null) {
      setEndDate(null); // Allow clearing the end date
      return;
    }

    if (startDate && setTimeToZero(date) < setTimeToZero(startDate)) {
      return;
    }
    setEndDate(date);
  };

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);

      fetchSuppliers();
      // fetchPolist();
      fetch_state();
      fetchPOStatus();
      fetchProductType();
      fetchProdList();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div id="mySidebar" className="customsidebar ">
        <a className="closebtn" onClick={() => toggleNav()}>
          ×
        </a>

        <div className=" content">
          <div className="filter row">
            <label
              className="filterLabel mb-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_selectDateRange
                ? constLabel.lbl_selectDateRange
                : "Select Date Range"}
            </label>
            <div className="fromDate col-12 col-md-6">
              <DatePicker
                id="fromDate"
                className="form-control form-control-sm"
                selected={startDate}
                showYearDropdown
                scrollableMonthYearDropdown
                dateFormat="dd/MM/yyyy"
                placeholderText="From Date"
                onChange={(date) => handleFromDateChange(date)}
                maxDate={new Date()}
                isClearable
              />
            </div>

            <div className="toDate col-12 col-md-6">
              <DatePicker
                id="toDate"
                className="form-control form-control-sm"
                showYearDropdown
                scrollableMonthYearDropdown
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="To Date"
                onChange={(date) => handleToDateChange(date)}
                maxDate={new Date()}
                isClearable
              />
            </div>
          </div>

          <div className="form-group typeahead-container">
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select Supplier "}
            </label>

            {/* <Typeahead
              id="basic-typeahead-single"
              labelKey="name"
              name="suppliername"
              multiple={false}
              size="sm"
              options={supplierList.data}
              isLoading={supplierList.loading}
              selected={filterConfig.suppliername}
              onChange={(val) => {
                
                if (val.length > 0) {
                  
                  setFilterConfig({ ...filterConfig, suppliername: val });
                } else {
                  setFilterConfig({ ...filterConfig, suppliername: [] });
                }
              }}
              placeholder="Search supplier name "
            /> */}

            <Multiselect
              options={supplierList.data}
              onSelect={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, suppliername: val });
                } else {
                  setFilterConfig({ ...filterConfig, suppliername: [] });
                }
              }}
              onRemove={(e) => {
                setFilterConfig({ ...filterConfig, suppliername: e });
              }}
              selectedValues={filterConfig.suppliername}
              displayValue="name"
              placeholder="Select supplier name"
              isLoading={supplierList.loading}
              customCloseIcon={
                <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                  x
                </p>
              }
              filterBy={() => true}
            />
            {!supplierList.loading && <span className="arrow-icon"></span>}
          </div>

          {/* <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select Client PO  "}
            </label> */}

          {/* <Typeahead
              id="basic-typeahead-single"
              labelKey="client_poid"
              name="client_poid"
              multiple={false}
              size="sm"
              options={po_list.data}
              isLoading={po_list.loading}
              selected={filterConfig.client_poid}
              onChange={(val) => {
                
                if (val.length > 0) {
                  
                  setFilterConfig({ ...filterConfig, client_poid: val });
                } else {
                  setFilterConfig({ ...filterConfig, client_poid: [] });
                }
              }}
              placeholder="Search by client PO-ID"
            /> */}

          {/* <Multiselect
              options={po_list.data}
              onSelect={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, client_poid: val });
                } else {
                  setFilterConfig({ ...filterConfig, client_poid: [] });
                }
              }}
              onRemove={(e) => {
                setFilterConfig({ ...filterConfig, client_poid: e });
              }}
              selectedValues={filterConfig.client_poid}
              displayValue="client_poid"
              placeholder="Search by client PO-ID"
              isLoading={po_list.loading}
              customCloseIcon={
                <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                  x
                </p>
              }
            />
          </div> */}

          <div className="form-group typeahead-container">
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select GST  "}
            </label>
            {/* 
            <Typeahead
              id="basic-typeahead-single"
              labelKey="gst"
              name="gst"
              multiple={false}
              size="sm"
              options={gst_list.data}
              isLoading={gst_list.loading}
              selected={filterConfig.gst}
              onChange={(val) => {
                
                if (val.length > 0) {
                  
                  setFilterConfig({ ...filterConfig, gst: val });
                } else {
                  setFilterConfig({ ...filterConfig, gst: [] });
                }
              }}
              placeholder="Search by GST number"
            /> */}

            <Multiselect
              options={gst_list.data}
              onSelect={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, gst: val });
                } else {
                  setFilterConfig({ ...filterConfig, gst: [] });
                }
              }}
              onRemove={(e) => {
                setFilterConfig({ ...filterConfig, gst: e });
              }}
              selectedValues={filterConfig.gst}
              displayValue="gst"
              placeholder="Search by GST number"
              isLoading={gst_list.loading}
              customCloseIcon={
                <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                  x
                </p>
              }
              filterBy={() => true}
            />
            {!gst_list.loading && <span className="arrow-icon"></span>}
          </div>

          <div className="form-group typeahead-container">
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select State"}
            </label>

            {/* <Typeahead
              id="basic-typeahead-single"
              labelKey="stateName"
              name="stateName"
              multiple={false}
              size="sm"
              options={state_list.data}
              isLoading={state_list.loading}
              selected={filterConfig.state}
              onChange={(val) => {
                
                if (val.length > 0) {
                  
                  setFilterConfig({ ...filterConfig, state: val });
                } else {
                  setFilterConfig({ ...filterConfig, state: [] });
                }
              }}
              placeholder="Search by state"
            /> */}

            <Multiselect
              options={state_list.data}
              onSelect={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, stateName: val });
                } else {
                  setFilterConfig({ ...filterConfig, stateName: [] });
                }
              }}
              onRemove={(e) => {
                setFilterConfig({ ...filterConfig, stateName: e });
              }}
              selectedValues={filterConfig.stateName}
              displayValue="stateName"
              placeholder="Search by state"
              isLoading={state_list.loading}
              customCloseIcon={
                <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                  x
                </p>
              }
              filterBy={() => true}
            />
            {!state_list.loading && <span className="arrow-icon"></span>}
          </div>

          <div className="form-group typeahead-container">
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select PO Status  "}
            </label>

            <Typeahead
              id="basic-typeahead-single"
              labelKey="postatus"
              name="postatus"
              multiple={false}
              // size="sm"
              options={poStatusList.data}
              isLoading={poStatusList.loading}
              selected={filterConfig.po_status}
              onChange={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, po_status: val });
                } else {
                  setFilterConfig({ ...filterConfig, po_status: [] });
                }
              }}
              placeholder="Search by PO status"
              filterBy={() => true}
            />
            {!poStatusList.loading && <span className="arrow-icon"></span>}
          </div>

          <div className="form-group typeahead-container">
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select Product Type  "}
            </label>

            {/* <Typeahead
              id="basic-typeahead-single"
              labelKey="productType"
              name="productType"
              multiple={false}
              size="sm"
              options={prodType.data}
              isLoading={prodType.loading}
              selected={filterConfig.prodType}
              onChange={(val) => {
                
                if (val.length > 0) {
                  
                  setFilterConfig({ ...filterConfig, prodType: val });
                } else {
                  setFilterConfig({ ...filterConfig, prodType: [] });
                }
              }}
              placeholder="Search by product type"
            /> */}

            <Multiselect
              options={prodType.data}
              onSelect={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, productType: val });
                } else {
                  setFilterConfig({ ...filterConfig, productType: [] });
                }
              }}
              onRemove={(e) => {
                setFilterConfig({ ...filterConfig, productType: e });
              }}
              selectedValues={filterConfig.productType}
              displayValue="productType"
              placeholder="Search by product type"
              isLoading={prodType.loading}
              customCloseIcon={
                <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                  x
                </p>
              }
              filterBy={() => true}
            />
            {!prodType.loading && <span className="arrow-icon"></span>}
          </div>

          <div className="form-group typeahead-container">
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select Product "}
            </label>

            {/* <Typeahead
              id="basic-typeahead-single"
              labelKey="productName"
              name="productName"
              multiple={false}
              size="sm"
              options={products.data}
              isLoading={products.loading}
              selected={filterConfig.productName}
              onChange={(val) => {
                
                if (val.length > 0) {
                  
                  setFilterConfig({ ...filterConfig, productName: val });
                } else {
                  setFilterConfig({ ...filterConfig, productName: [] });
                }
              }}
              placeholder="Search by product name"
            /> */}

            <Multiselect
              options={products.data}
              onSelect={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, productName: val });
                } else {
                  setFilterConfig({ ...filterConfig, productName: [] });
                }
              }}
              onRemove={(e) => {
                setFilterConfig({ ...filterConfig, productName: e });
              }}
              selectedValues={filterConfig.productName}
              displayValue="productName"
              placeholder="Search by product"
              isLoading={products.loading}
              customCloseIcon={
                <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                  x
                </p>
              }
              filterBy={() => true}
            />
            {!products.loading && <span className="arrow-icon"></span>}
          </div>
          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.voucher_no ? constLabel.voucher_no : "Voucher Number"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by voucher number"
              value={filterConfig.voucher_no}
              onChange={(val) => {
                const inputValue = val.target.value; // Trim leading and trailing whitespaces
                // console.log("inputValue",inputValue); 
                // console.log("inputValue",inputValue.length); 
                // console.log("!inputValue.trim()",!inputValue.trim())
                if (!inputValue.trim()) {
                  // console.log("inputValue2",inputValue)
                  if (filterConfig.voucher_no.length == 1) {
                    // console.log("inputValue3",inputValue)
                    setFilterConfig({ ...filterConfig, voucher_no: "" });
                  }

                  if (inputValue.length == 0) {
                    setFilterConfig({ ...filterConfig, voucher_no: "" });
                  }

                } else {
                  // console.log("inputValue4",inputValue)
                  setFilterConfig({
                    ...filterConfig,
                    voucher_no: inputValue,
                  });
                }
              }}
            />
          </div>

          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_quote_ref ? constLabel.lbl_quote_ref : "Quote Ref"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by quote ref"
              value={filterConfig.quote_ref}
              onChange={(val) => {
                const inputValue = val.target.value; // Trim leading and trailing whitespaces
                // console.log("inputValue",inputValue); 
                // console.log("inputValue",inputValue.length); 
                // console.log("!inputValue.trim()",!inputValue.trim())
                if (!inputValue.trim()) {
                  // console.log("inputValue2",inputValue)
                  if (filterConfig.quote_ref.length == 1) {
                    // console.log("inputValue3",inputValue)
                    setFilterConfig({ ...filterConfig, quote_ref: "" });
                  }

                  if (inputValue.length == 0) {
                    setFilterConfig({ ...filterConfig, quote_ref: "" });
                  }

                } else {
                  // console.log("inputValue4",inputValue)
                  setFilterConfig({
                    ...filterConfig,
                    quote_ref: inputValue,
                  });
                }
              }}
            />
          </div>

          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              From:{priceRange.from}
            </label>

            <input
              className="form-range"
              type="range"
              min={0}
              max={100000}
              step={1000}
              value={priceRange.from}
              onChange={(event) => handleSliderChange(event, "from")}
            />
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              To: {priceRange.to}
            </label>
            <input
              className="form-range"
              type="range"
              min={0}
              max={100000}
              step={1000}
              value={priceRange.to}
              onChange={(event) => handleSliderChange(event, "to")}
            />
            {errorMessage && (
              <span className="text-danger err-msg">{errorMessage}</span>
            )}
          </div>

          <div>
            <div className="form-check form-switch mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label lbl-style roboto-bold">
                {"PO with Inward"}
              </label>
            </div>
          </div>
          <div className="my-3 d-flex justify-content-end">
            <button
              onClick={() => clearFilter()}
              className="btn btn-sm btn-yellow "
            >
              <FontAwesomeIcon
                style={{ color: "#344454" }}
                icon={faBan}
                size="sm"
              />{" "}
              {constLabel?.lbl_clear_filter
                ? constLabel.lbl_clear_filter
                : "Clear Filter"}
            </button>
          </div>
        </div>
      </div>
      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  <Tooltip id={"open-tooltip"} place="top" />
                  <img
                    src={Slide}
                    height="32"
                    width="32"
                    className="cursor_pointer my-1 me-2"
                    onClick={() => toggleNav()}
                    data-tooltip-id={"open-tooltip"}
                    data-tooltip-content={"Filters"}
                  />
                  <h1 className="bold">
                    {constLabel?.lbl_purchase_order
                      ? constLabel.lbl_purchase_order
                      : "Purchase Order"}
                  </h1>
                </div>

                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() =>
                        navigate(
                          `/pcms/create/purchase-order/${btoa("null")}/${btoa(
                            "null"
                          )}`,
                          { state: dataList.data[0] }
                        )
                      }
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_create_purchase_order
                        ? constLabel.lbl_create_purchase_order
                        : "Create Purchase Order"}
                    </button>
                  )}

                  {privilage.report &&
                    <>
                      <Tooltip id={"download-tooltip"} place="top" />
                      <button
                        className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                        data-tooltip-id={"download-tooltip"}
                        data-tooltip-content={
                          constLabel?.lbl_download_template
                            ? constLabel.lbl_download_template
                            : "Download Template"}
                        onClick={() => {
                          setDownloadTemplate(true);
                          get_po_template().then((res) => {
                            // console.log("res", res);
                            base64ToExcel(res.data);
                          }, (err) => {
                            console.log("err", err);
                            toast.error("Something went wrong", {
                              autoClose: 2000,
                            });
                            setDownloadTemplate(false);
                          })
                        }}
                        disabled={downloadTemplate}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}{downloadTemplate && <Spinner animation="border" size="sm" />}
                      </button>
                    </>
                  }

                  {privilage.report &&
                    <>
                      <Tooltip id={"upload-tooltip"} place="top" />
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png" // Define allowed file types
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the input element
                        id="fileInput"
                      />
                      <label htmlFor="fileInput">
                        <button
                          className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                          data-tooltip-id={"upload-tooltip"}
                          data-tooltip-content={
                            constLabel?.lbl_import_po
                              ? constLabel.lbl_import_po
                              : "Import PO"}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                          onChange={handleFileChange}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faUpload}
                            size="sm"
                          />
                        </button>
                      </label>
                    </>
                  }

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                          <li key={ind}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                onChange={() => handleClick(ind)}
                                value=""
                                checked={val.flag}
                              />
                              <label
                                className=""
                                style={{ fontSize: "13px" }}
                                htmlFor="flexCheckDefault"
                              >
                                {val.label}
                              </label>
                            </div>
                          </li>
                        ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <div
                    className="table-responsive"
                  >
                    <table className="table table-bordered">
                      <thead className="table-grey roboto-bold">
                        <tr className="">
                          <th scope="col">{"Sr.No"}</th>

                          {showLabel.map((v, i) =>
                            v.label === "PO Number" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_po_id
                                  ? constLabel.lbl_po_id
                                  : "PO Number"}
                              </th>
                            ) : v.label === "Supplier" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_supplier_name
                                  ? constLabel.lbl_supplier_name
                                  : "Supplier"}
                              </th>
                            ) : v.label === "Purchase Date" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_date
                                  ? constLabel.lbl_date
                                  : "Purchase Date"}
                              </th>
                            ) : v.label === "Value" && v.flag ? (
                              <th key={i} scope="col">
                                {"Value"}
                              </th>
                            ) : v.label === "Status" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_status
                                  ? constLabel.lbl_status
                                  : "Status"}
                              </th>
                            ) : v.label === "Invoice To" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_invoice_to
                                  ? constLabel.lbl_invoice_to
                                  : "Invoice To"}
                              </th>
                            ) : v.label === "Disptach To" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_dispatch_to
                                  ? constLabel.lbl_dispatch_to
                                  : "Dispatch To"}
                              </th>
                            ) : v.label === "Quote Ref" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_quote_ref
                                  ? constLabel.lbl_quote_ref
                                  : "Quote Ref"}
                              </th>
                            ) : v.label === "Product Name" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_product_name
                                  ? constLabel.lbl_product_name
                                  : "Product Name"}
                              </th>
                            ) : v.label === "Quantity" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_quantity
                                  ? constLabel.lbl_quantity
                                  : "Quantity"}
                              </th>
                            ) : v.label === "Unit" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_unit
                                  ? constLabel.lbl_unit
                                  : "Unit"}
                              </th>
                            ) : v.label === "Remark" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_remark
                                  ? constLabel.lbl_remark
                                  : "Remark"}
                              </th>
                            ) : v.label === "State" && v.flag ? (
                              <th key={i} scope="col">
                                {"State"}
                              </th>
                            ) : v.label === "GSTN" && v.flag ? (
                              <th key={i} scope="col">
                                {"GSTN"}
                              </th>
                              // ) : v.label === "Client PO No" && v.flag ? (
                              //   <th key={i} scope="col">
                              //     {constLabel?.lbl_client_po_no
                              //       ? constLabel.lbl_client_po_no
                              //       : "Client PO No"}
                              //   </th>
                            ) : v.label === "Credit Period" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_credit_period
                                  ? constLabel.lbl_credit_period
                                  : "Credit Period"}
                              </th>
                            ) : null
                          )}

                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className=" roboto">
                        {dataList.data.map((val, ind) => (
                          <tr key={ind}>
                            <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                            {showLabel.map((item, idx) =>
                              item.label === "PO Number" && item.flag ? (
                                <td key={idx}>
                                  {" "}
                                  {val.poid != "" && val.poid !== null
                                    ? val.poid
                                    : "NA"}
                                </td>
                              ) : item.label === "Supplier" && item.flag ? (
                                <td key={idx}>
                                  {" "}
                                  {val.suppliername ? val.suppliername : "NA"}
                                </td>
                              ) : item.label === "Purchase Date" &&
                                item.flag ? (
                                <td key={idx}>
                                  {val.po_date
                                    ? convertDate(val.po_date, "-")
                                    : "NA"}
                                </td>
                              ) : item.label === "Value" && item.flag ? (
                                <td key={idx}>
                                  {val.net_amount ? val.net_amount : "NA"}
                                </td>
                              ) : item.label === "Status" && item.flag ? (
                                <td key={idx}>
                                  {val.po_status ? val.po_status : "NA"}
                                </td>
                              ) : item.label === "Invoice To" && item.flag ? (
                                <td key={idx}>
                                  {" "}
                                  {val.invoice_toname
                                    ? val.invoice_toname
                                    : "NA"}
                                </td>
                              ) : item.label === "Disptach To" && item.flag ? (
                                <td key={idx}>
                                  {val.despatch_toname
                                    ? val.despatch_toname
                                    : "NA"}
                                </td>
                              ) : item.label === "Quote Ref" && item.flag ? (
                                <td key={idx}>
                                  {val.quote_ref ? val.quote_ref : "NA"}
                                </td>
                              ) : item.label === "Product Name" && item.flag ? (
                                <td key={idx}>
                                  {val.PO_line_item?.[0]?.productname ? val.PO_line_item?.[0]?.productname : "NA"}
                                </td>
                              ) : item.label === "Quantity" && item.flag ? (
                                <td key={idx}>
                                  {val.PO_line_item?.[0]?.quantity ? val.PO_line_item?.[0]?.quantity : "NA"}
                                </td>
                              ) : item.label === "Unit" && item.flag ? (
                                <td key={idx}>
                                  {val.PO_line_item?.[0]?.unit ? val.PO_line_item?.[0]?.unit : "NA"}
                                </td>
                              ) : item.label === "Remark" && item.flag ? (
                                <td key={idx}>
                                  {val.remark ? val.remark : "NA"}
                                </td>
                              ) : item.label === "State" && item.flag ? (
                                <td key={idx}>
                                  {val.supplier_id?.state_name
                                    ? val.supplier_id.state_name
                                    : "NA"}
                                </td>
                              ) : item.label === "GSTN" && item.flag ? (
                                <td key={idx}>
                                  {val.supplier_id?.gst
                                    ? val.supplier_id.gst
                                    : "NA"}
                                </td>
                                // ) : item.label === "Client PO No" && item.flag ? (
                                //   <td key={idx}>
                                //     {val.client_poid != "" &&
                                //     val.client_poid !== null
                                //       ? val.client_poid
                                //       : "NA"}
                                //   </td>
                              ) : item.label === "Credit Period" &&
                                item.flag ? (
                                <td key={idx}>
                                  {val.credit_period ? val.credit_period : "NA"}
                                </td>
                              ) : null
                            )}

                            <td>
                              {privilage.write &&
                                <>
                                  <Tooltip id={"edit-tooltip" + ind} place="top" />
                                  <Edit
                                    data-tooltip-id={"edit-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_edit_po
                                        ? constLabel.lbl_edit_po
                                        : "Edit PO"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={() =>
                                      navigate("/pcms/update/purchase-order", {
                                        state: val,
                                      })
                                    }
                                  />
                                </>
                              }

                              {privilage.read &&
                                <>
                                  <Tooltip id={"view-tooltip" + ind} place="top" />
                                  <Eye
                                    data-tooltip-id={"view-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_view_po
                                        ? constLabel.lbl_view_po
                                        : "View PO"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={() =>
                                      navigate(
                                        `/pcms/view/purchase-order`,
                                        { state: val }
                                      )
                                    }
                                  />
                                </>
                              }

                              {privilage.read &&
                                <>
                                  <Tooltip id={"pdf-tooltip" + ind} place="top" />
                                  <FileText
                                    data-tooltip-id={"pdf-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_generate_pdf
                                        ? constLabel.lbl_generate_pdf
                                        : "Generate PDF"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={() => {
                                      navigate(
                                        `/pcms/view/purchase-order-pdf/${val.voucher_no}`,
                                        { state: val }
                                      );
                                    }}
                                  />
                                </>
                              }

                              {privilage.delete &&
                                <>
                                  <Tooltip
                                    id={"delete-tooltip" + ind}
                                    place="top"
                                  />
                                  <Trash2
                                    data-tooltip-id={"delete-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_delete_po
                                        ? constLabel.lbl_delete_po
                                        : "Delete PO"
                                    }
                                    className="menu_icon_red cursor_pointer"
                                    size={20}
                                    onClick={() => delPO(val)}
                                  />
                                </>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No Purchase Orders Found"}
                  </div>
                )}

                {dataList.loading ? null : (
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <p className="mb-0">
                      Show
                      <select
                        className="mx-1"
                        defaultValue={entriesPerPage}
                        onChange={(e) => {
                          setEntriesPerPage(e.target.value);
                          fetchPOList(1, e.target.value);
                        }}
                      >
                        <option value={"10"}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Entries
                    </p>
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                    <Pagination className="my-2">
                      <Pagination.Prev
                        disabled={page === 1 ? true : false}
                        onClick={() => {
                          fetchPOList(page - 1, entriesPerPage);
                          setSrNo((prevC) =>
                            page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          page === maxPage || maxPage === 0 ? true : false
                        }
                        onClick={() => {
                          fetchPOList(page + 1, entriesPerPage);
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PurchaseOrd;
